import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { step4Data } from '../../store/actions';
import { RootState } from '../../store/reducers';
import { step4State, stepProps } from '../../common/Type';

import ContinueModal from '../../common/Modal/ContinueModal';
import { Step, StepNum, StepTitle, FlexButton, NextTrue, NextFalse, AverageBox, Thick, Light, AverageCall, Underline2, CommonInputBox, Information } from '../../common/css/OrderCore';
import { chargeFilter } from '../../lib/function';

function Chapter4({step, setStep, isChecked, setIsChecked, prevStep}: stepProps) {
  /* useSelector */
  const storeData = useSelector((store: RootState) => store.dataReducer);
  const dispatch = useDispatch();
  
  const [ motorcycle, setMotorCycle ] = useState(storeData.motorcycle_call);
  const [ damas, setDamas ] = useState(storeData.damas_call);
  const [ truck, setTruck ] = useState(storeData.truck_call);
  const [ totalCall, setTotalCall ] = useState(storeData.daily_total_call);

  const handleMotorcycle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMotorCycle(e.target.value); // 오토바이
  };
  const handleDamas = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDamas(e.target.value); // 다마스/라보
  };
  const handleTruck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTruck(e.target.value); // 1톤 트럭이상
  };
  useEffect(() => {
    const handleTotalCall = () => {
      if( motorcycle !== '' && damas !== '' && truck !=='') 
      return setTotalCall(
        (parseInt(motorcycle) + parseInt(damas) + parseInt(truck)).toString()
      );
    }
    handleTotalCall();
  },[damas, motorcycle, truck]);

  /* store에 데이터 저장 */
  const nextStep = () => {
    setStep(step + 1);
    document.documentElement.scrollTo(0, 0);

    const inputData: step4State = {
      motorcycle_call: motorcycle,
      damas_call: damas,
      truck_call: truck,
      daily_total_call: totalCall
    };
    dispatch(step4Data(inputData));
  };

  /* 금액 정규표현식 */
  const countCheck = /^[0-9]+$/;
  const regMotorcycle = countCheck.test(motorcycle);
  const regDamas = countCheck.test(damas);
  const regTruck = countCheck.test(truck);

  /* 필수 입력 체크 */
  const [ motorcycleError, setMotorcycleError ] = useState(false);
  const [ damasError, setDamasError ] = useState(false);
  const [ truckError, setTruckError ] = useState(false);

  const handleFalse = () => {
    setIsChecked(!isChecked);

    // 오토바이
    if (!regMotorcycle) {
      setMotorcycleError(true);
    } else {
      setMotorcycleError(false);
    };
    // 다마스
    if (!regDamas) {
      setDamasError(true);
    } else {
      setDamasError(false);
    };
    // 트럭
    if (!regTruck) {
      setTruckError(true);
    } else {
      setTruckError(false);
    };
  };

  /* [ 다음 ] 버튼 활성화 */
  const regExp_4 = () => {
    if(
      regMotorcycle &&
      regDamas &&
      regTruck &&
      totalCall !== ''
    ) return true;
    return false;
  };

  return (
    <>
      <Step>
        <StepNum>Step 4</StepNum>
        <StepTitle>일 평균 콜 수 정보</StepTitle>
      </Step>
      <CommonInputBox>
        <p>오토바이</p>
        <div className='input_description'>
          하루 평균 접수 받아서 최종 완료되는 오토바이 콜 수를 의미합니다.
        </div>
        <Information>
          <input
            type='number'
            className={ motorcycleError ? 'inputError' : 'inputPass'}
            defaultValue={motorcycle}
            onChange={handleMotorcycle}
            onWheel={ event => event.currentTarget.blur() }
          />
          <div>건</div>
        </Information>
      </CommonInputBox>
      <CommonInputBox>
        <p>다마스 / 라보</p>
        <div className='input_description'>
          하루 평균 접수 받아서 최종 완료되는 다마스와 라보의 콜 수를 의미합니다.
        </div>
        <Information>
          <input
            type='number'
            className={ damasError ? 'inputError' : 'inputPass'}
            defaultValue={damas}
            onChange={handleDamas}
            onWheel={ event => event.currentTarget.blur() }
          />
          <div>건</div>
        </Information>
      </CommonInputBox>
      <CommonInputBox>
        <p>1톤 트럭 이상</p>
        <div className='input_description'>
          하루 평균 접수 받아서 최종 완료되는 1통 트럭 이상의 콜 수를 의미합니다.
        </div>
        <Information>
          <input
            type='number'
            className={ truckError ? 'inputError' : 'inputPass'}
            defaultValue={truck}
            onChange={handleTruck}
            onWheel={ event => event.currentTarget.blur() }
          />
          <div>건</div>
        </Information>
      </CommonInputBox>
      <AverageBox>
        <Thick />
        <Light />
        <AverageCall>
          <div>
            일 평균 콜 수
            <Underline2 />
          </div>
        </AverageCall>
        <div className='average_money'>{chargeFilter(totalCall)} 건</div>
      </AverageBox>
      <FlexButton>
        <NextFalse onClick={prevStep}>이전</NextFalse>
        { regExp_4()
          ? <NextTrue onClick={nextStep}>다음</NextTrue>
          : <NextFalse onClick={handleFalse}>다음</NextFalse>
        }
      </FlexButton>
      { isChecked &&
        <ContinueModal modalButton={handleFalse} />
      }
    </>
  )
};

export default Chapter4;