import styled from "styled-components";

/* STEP 1~7 */
export const Step = styled.section`
  @media screen and (min-width: 1025px) {
    margin-bottom: 1rem;
    display: flex;
    height: 2rem;
    line-height: 2rem;
    font-weight: 700;
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 4vw;
    display: flex;
    height: 9.5vw;
    line-height: 12vw;
    font-weight: 700;
  }
`;
export const StepNum = styled.div`
  @media screen and (min-width: 1025px) {
    margin: auto 0.8rem auto 0;
    text-align: center;
    width: 4rem;
    height: 1.5rem;
    line-height: 1.6rem;
    font-size: 0.875rem;
    border-radius: 30px;
    color: #fff;
    background-color: #007fff;
    box-shadow: 0px 8px 8px -5px rgb(1 129 251 / 55%);
  }

  @media screen and (max-width: 1024px) {
    margin: 3vw 1vw auto 0;
    text-align: center;
    width: 15vw;
    height: 5.5vw;
    line-height: 5.6vw;
    font-size: 3vw;
    font-weight: 900;
    border-radius: 30px;
    color: #fff;
    background-color: #007fff;
    box-shadow: 0px 8px 8px -5px rgb(1 129 251 / 55%);
  }
`;
export const StepTitle = styled.div`
  @media screen and (min-width: 1025px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 1024px) {
    font-size: 5.5vw;
  }
`;


/* 공통 InputBox */
export const CommonInputBox = styled.section`
  @media screen and (min-width: 1025px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.688rem;
    padding: 2rem;
    font-size: 0.87rem;
    line-height: 1.4rem;
    p {
      font-size: 1.3rem;
      font-weight: 600;
    }
    .input_description {
      margin-top: 0.5rem;
      color: #454545;
    }

    border: 1px solid #d9d9d9;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 4vw;
    padding: 7vw 3vw;
    font-size: 2.8vw;
    line-height: 5vw;
    p {
      font-size: 5vw;
      font-weight: 600;
    }
    .input_description {
      margin-top: 3.5vw;
      color: #454545;
      div {
        display: inline;
      }
    }

    border: 1px solid #d9d9d9;
  }
`;
export const Information = styled.div`
  @media screen and (min-width: 1025px) {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    input: focus {
      outline: none;
      border: 1px solid #007fff;
    }

    .inputPass {
      margin-right: 0.5rem;
      width: 10rem;
      height: 1.7rem;
      text-align: right;

      font-size: 1rem;
      font-weight: 600;
      color: #0181FB;
      border: 1px solid #d9d9d9;
    }
    .inputError {
      margin-right: 0.5rem;
      width: 10rem;
      height: 1.7rem;
      text-align: right;

      font-size: 1rem;
      font-weight: 600;
      color: #0181FB;
      border: 1px solid #ff0000;
    }

    div {
      height: 2rem;
      line-height: 2rem;
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    margin-top: 8.5vw;

    input: focus {
      outline: none;
      border: 1px solid #007fff;
    }

    .inputPass {
      margin-right: 2vw;
      width: 40vw;
      height: 8.5vw;
      text-align: right;

      font-size: 4vw;
      font-weight: 600;
      color: #0181FB;
      border: 1px solid #d9d9d9;
    }
    .inputError {
      margin-right: 2vw;
      width: 40vw;
      height: 8.5vw;
      text-align: right;

      font-size: 4vw;
      font-weight: 600;
      color: #0181FB;
      border: 1px solid #ff0000;
    }

    div {
      height: 4vw;
      line-height: 10vw;
    }
  }
`;

/* 평균 합계 */
export const AverageBox = styled.div`
  @media screen and (min-width: 1025px) {
    display: flex;
    flex-direction: column;
    margin: 1.719rem 0 0.688rem 0;
    border-bottom: 1px solid #d9d9d9;
    .average_money {
      display: flex;
      justify-content: flex-end;
      padding: 1.5rem 2rem;
      font-size: 2rem;
      font-weight: 600;
      color: #0181FB;  
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 4.5vw;
    border-bottom: 1px solid #d9d9d9;
    .average_money {
      display: flex;
      justify-content: flex-end;
      padding: 1.5rem 2rem;
      font-size: 6vw;
      font-weight: 600;
      color: #0181FB;  
    }
  }
`;
export const Thick = styled.div`
  border-top: 5px solid #0181FB;
`;
export const Light = styled.div`
  margin-top: 0.1rem;
  border-top: 1px solid #0181FB;
`;
export const AverageSales = styled.div`
  @media screen and (min-width: 1025px) {
    margin: 1rem 2rem;
    width: 8rem;
    font-size: 1.3rem;
    font-weight: 600;
  }

  @media screen and (max-width: 1024px) {
    margin: 6.5vw 8vw;
    width: 35vw;
    font-size: 5vw;
    font-weight: 600;
  }
`;
export const AverageCall = styled.div`
  @media screen and (min-width: 1025px) {
    margin: 1rem 2rem;
    width: 7rem;
    font-size: 1.3rem;
    font-weight: 600;
  }

  @media screen and (max-width: 1024px) {
    margin: 6.5vw 8vw;
    width: 35vw;
    font-size: 5vw;
    font-weight: 600;
  }
`;
export const Underline = styled.div`
  @media screen and (min-width: 1025px) {
    margin-top: -0.3rem;
    border-bottom: 5px solid #2F79DE;
    opacity: 0.5;
  }

  @media screen and (max-width: 1024px) {
    width: 30.5vw;
    margin-top: -0.3rem;
    border-bottom: 5px solid #2F79DE;
    opacity: 0.5;
  }
`;
export const Underline2 = styled.div`
  @media screen and (min-width: 1025px) {
    margin-top: -0.3rem;
    border-bottom: 5px solid #2F79DE;
    opacity: 0.5;
  }

  @media screen and (max-width: 1024px) {
    width: 26.7vw;
    margin-top: -0.3rem;
    border-bottom: 5px solid #2F79DE;
    opacity: 0.5;
  }
`;



/* [이전][다음] 버튼 */
export const FlexButton = styled.div`
  @media screen and (min-width: 1025px) {
    display: flex;
    margin: 0.688rem auto 14.813rem auto;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    margin: 3vw auto 20vw auto;
    font-size: 4.2vw;
    font-weight: 500;
  }
`;
export const NextTrue = styled.div`
  @media screen and (min-width: 1025px) {
    margin: 0 1rem;
    width: 6rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;

    color: #fff;
    background-color: #007fff;
    box-shadow: 0px 10px 10px -5px rgba(1 129 251 / 55%);
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }

  @media screen and (max-width: 1024px) {
    margin: 0 4vw;
    width: 23vw;
    height: 12vw;
    line-height: 12.5vw;
    text-align: center;

    color: #fff;
    background-color: #007fff;
    box-shadow: 0px 10px 10px -5px rgba(1 129 251 / 55%);
    border-radius: 5px;
  }
`;
export const NextFalse = styled.div`
@media screen and (min-width: 1025px) {
  margin: 0 1rem;
  width: 6rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;

  color: #fff;
  background-color: #d1d1d1;
  box-shadow: 0px 10px 10px -5px rgba(209 209 209);
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}

@media screen and (max-width: 1024px) {
  margin: 0 4vw;
  width: 23vw;
  height: 12vw;
  line-height: 12.5vw;
  text-align: center;

  color: #fff;
  background-color: #d1d1d1;
  box-shadow: 0px 10px 10px -5px rgba(209 209 209);
  border-radius: 5px;
}
`;
