import React from 'react';
import styled from 'styled-components';
import { ModalWrapper, ModalPopup } from '../css/ModalCore';
import stop from '../../assets/Modal/stop.webp';

interface footerProps {
  modalButton: any;
}

const StopModal = ({modalButton}: footerProps) => {
  return (
    <>
    <ModalWrapper>
      <ModalPopup>
        <Notices>
          <Logo />
          <Text>
            <div>작성을 그만하시겠습니까?</div>
            <section>작성하신 정보는 모두 삭제됩니다.</section>
          </Text>
        </Notices>
        <Buttons>
          <StopBtn href='/'>
            그만하기
          </StopBtn>
          <ContinueBtn onClick={modalButton}>작성계속하기</ContinueBtn>
        </Buttons>
      </ModalPopup>
    </ModalWrapper>
    </>
  );
};

export default StopModal;

const Notices = styled.div`
  @media screen and (min-width: 1025px) {
    padding: 1.5rem 3rem 0 3rem;
    height: 15rem;
  }

  @media screen and (max-width: 1024px) {
    padding: 5vw 6vw 0 6vw;
    height: 45vw;
  }
`;

const Logo = styled.div`
  @media screen and (min-width: 1025px) {
    margin-top: 1rem;
    width: 5rem;
    height: 5rem;
    background-image: url(${stop});
    background-repeat: no-repeat;
    background-size: 4rem;
    image-rendering: -webkit-optimize-contrast;
  }

  @media screen and (max-width: 1024px) {
    width: 13vw;
    height: 13vw;
    background-image: url(${stop});
    background-repeat: no-repeat;
    background-size: 11vw;
  }
`;

const Text = styled.div`
  @media screen and (min-width: 1025px) {
    margin-top: 0.3rem;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.8rem;
    section {
      font-size: 1rem;
      font-weight: normal;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-top: 2vw;
    font-size: 4vw;
    font-weight: 700;
    line-height: 6vw;
    section {
      font-size: 3vw;
      font-weight: normal;
    }
  }
`;

const Buttons = styled.div`
  @media screen and (min-width: 1025px) {
    display: flex;
    align-items: center;
    height: 3.5rem;

    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    line-height: 3.5rem;

    color: #fff;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    height: 11vw;

    font-size: 3vw;
    font-weight: 500;
    text-align: center;
    line-height: 10vw;
    color: #fff;
  }
`;

const StopBtn = styled.a`
  @media screen and (min-width: 1025px) {
    width: 12rem;
    background-color: #747474;
    &:hover {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 34vw;
    background-color: #747474;
  }
`;

// width -> 확대*축소할때 간헐적으로 빈공간 생긴다면 calc 이용
const ContinueBtn = styled.div`
  @media screen and (min-width: 1025px) {
    width: calc(100% - 12rem);
    background-color: #007fff;
    &:hover {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 1024px) {
    width: calc(100% - 34vw);
    background-color: #007fff;
  }
`;
