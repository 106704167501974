import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { step5Data } from '../../store/actions';
import { RootState } from '../../store/reducers';
import { step5State, stepProps } from '../../common/Type';
import ContinueModal from '../../common/Modal/ContinueModal';
import { Step, StepNum, StepTitle, FlexButton, NextTrue, NextFalse, CommonInputBox, Information } from '../../common/css/OrderCore';

function Chapter5({step, setStep, isChecked, setIsChecked, prevStep}: stepProps) {
  /* useSelector */
  const storeData = useSelector((store: RootState) => store.dataReducer);
  const dispatch = useDispatch();

  const [ ratio, setRatio ] = useState(storeData.corporation_driver_ratio);

  const handleRatio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRatio(e.target.value);
  };

  const nextStep = () => {
    setStep(step + 1);
    document.documentElement.scrollTo(0, 0);

    const inputData: step5State = {
      corporation_driver_ratio: ratio
    }
    dispatch(step5Data(inputData));
  };

  /* 퍼센트 정규표현식 */
  const percentCheck = /^(100(\.00?)?|[1-9]?\d(\.\d\d?)?)$/;
  const regRatio = percentCheck.test(ratio);

  /* 필수 입력 체크 */
  const [ ratioError, setRatioError ] = useState(false);

  const handleFalse = () => {
    setIsChecked(!isChecked);

    if (!regRatio) {
      setRatioError(true);
    } else {
      setRatioError(false);
    };
  };

  return (
    <>
      <Step>
        <StepNum>Step 5</StepNum>
        <StepTitle>자기사 처리 비율</StepTitle>
      </Step>
      <CommonInputBox>
        <p>자기사 처리 비율</p>
        <div className='input_description'>
          자기사 처리 비율이란 전체 콜 중에서 공유기사가 아닌 자기사가 처리한 콜 수가 차지하는 비율을 나타냅니다.
        </div>
        <Information>
          <input
            type='number'
            className={ ratioError ? 'inputError' : 'inputPass'}
            defaultValue={ratio}
            onChange={handleRatio}
            onWheel={ event => event.currentTarget.blur() }
          />
          <div>%</div>
        </Information>
      </CommonInputBox>
      <FlexButton>
        <NextFalse onClick={prevStep}>이전</NextFalse>
        { regRatio
          ? <NextTrue onClick={nextStep}>다음</NextTrue>
          : <NextFalse onClick={handleFalse}>다음</NextFalse>
        }      
      </FlexButton>
      { isChecked &&
        <ContinueModal modalButton={handleFalse} />
      }
    </>
  )
};

export default Chapter5;