import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { step6Data } from '../../store/actions';
import { RootState } from '../../store/reducers';
import { initalStateType, postStateType, stepProps, step6State } from '../../common/Type';

import { copyObject } from '../../lib/function';
import { postEmail } from '../../lib/api/base';

import LastCheckModal from '../../common/Modal/LastCheckModal';
import ContinueModal from '../../common/Modal/ContinueModal';
import { Step, StepNum, StepTitle, FlexButton, NextTrue, NextFalse } from '../../common/css/OrderCore';
import arrow from '../../assets/Main/arrow.png';

function Chapter6({step, setStep, isChecked, setIsChecked, prevStep}: stepProps) {
  /* useSelector */
  const storeData: initalStateType = useSelector((store: RootState) => store.dataReducer);
  const dispatch = useDispatch();

  const [ name, setName ] = useState(storeData.customer_name);
  const [ phone, setPhone ] = useState(storeData.phone);
  const [ emailId, setEmailId ] = useState(storeData.email.split('@')[0]);
  const [ domain, setDomain ] = useState(storeData.email.split('@')[1]);
  const [ finalCheck, setFinalCheck ] = useState(false);

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };
  const handleEmailId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailId(e.target.value);
  };
  const handleDomain = (e: any) => {
    setDomain(e.target.value);
  };

  /* chapter 1 ~ 5의 nextStep과는 다르게 기능 분리 */
  const finalStep = () => { // 데이터 등록 & 모달 기능
    const inputData: step6State = {
      customer_name: name,
      phone: phone,
      email: emailId + '@' + domain
    }
    dispatch(step6Data(inputData));
    setFinalCheck(!finalCheck);
  };

  const sendEmail = async () => {
    setStep(step + 1); // 여기서 step + 1
    document.documentElement.scrollTo(0, 0);

    /**
     * @description 얕은 복사 copyObject
     * useSelector로 데이터를 저장중이라 이메일 전송시 만원단위 환산
     * initialStateType => postStateType 타입 변환
    /* 얕은 복사 copyObject */
    let postData: postStateType = await copyObject(storeData);
      postData.credit_sales = Number(storeData.credit_sales);
      postData.card_sales = Number(storeData.card_sales);
      postData.cash_sales = Number(storeData.cash_sales);
      postData.monthly_total_sales = Number(storeData.monthly_total_sales);
      postData.monthly_net_sales = Number(storeData.monthly_net_sales);
      postData.motorcycle_call = Number(storeData.motorcycle_call);
      postData.damas_call = Number(storeData.damas_call);
      postData.truck_call = Number(storeData.truck_call);
      postData.daily_total_call = Number(storeData.daily_total_call);
      postData.corporation_driver_ratio = Number(storeData.corporation_driver_ratio);
    // API전송
    return postEmail(postData);
    // return console.log(postData);
  };

  /* 이름 정규표현식 */
  const nameCheck = /^[가-힣a-zA-Z0-9]+$/;
  const regName = nameCheck.test(name);
  /* 연락처 정규표현식 */
  const phoneCheck1 = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; // 폰번호
  const phoneCheck2 = /^(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))-?(\d{3,4})-?(\d{4})$/; //지역번호
  const phoneCheck3 = /^\d{4}-?\d{4}$/; // 대표번호
  const regPhone1 = phoneCheck1.test(phone);
  const regPhone2 = phoneCheck2.test(phone);
  const regPhone3 = phoneCheck3.test(phone);
  /* 이메일앞자리 정규표현식 */
  const emailIDCheck = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*$/;
  const regEmailID = emailIDCheck.test(emailId);
  /* 이메일뒷자리 정규표현식 */
  const domainCheck = /([a-z0-9\\-]+\.)+[a-z]{2,6}$/;
  const regDomain = domainCheck.test(domain);

  /* 필수 입력 체크 */
  const [ nameError, setNameError ] = useState(false);
  const [ phoneError, setPhoneError ] = useState(false);
  const [ emailIDError, setEmailIDError ] = useState(false);
  const [ domainError, setDomainError ] = useState(false);

  const handleFalse = () => {
    setIsChecked(!isChecked);

    // 이름
    if (!regName) {
      setNameError(true);
    } else {
      setNameError(false);
    };
    // 핸드폰 번호
    if (!regPhone1 || !regPhone2 || !regPhone3) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    };
    // 이메일 앞자리
    if (!regEmailID) {
      setEmailIDError(true);
    } else {
      setEmailIDError(false);
    };
    // 이메일 뒷자리
    if (!regDomain) {
      setDomainError(true);
    } else {
      setDomainError(false);
    };
  };

  /* [ 다음 ] 버튼 활성화 */
  const regExp_6 = () => {
    if(
      regName &&
      (regPhone1 || regPhone2 || regPhone3) &&
      regEmailID &&
      regDomain
    ) return true;
    return false;
  };

  return (
    <>
      <Step>
        <StepNum>Step 6</StepNum>
        <StepTitle>예상 매매가 확인하기</StepTitle>
        <LastTitle> *마지막</LastTitle>
      </Step>
      <InputBox>
        <TableBox>
          <tbody>
            <tr>
              <td>이름</td>
              <td>
                <input 
                  type='text'
                  placeholder='이름'
                  className={ nameError ? 'step6_inputError' : 'step6_inputPass' }
                  defaultValue={name}
                  onChange={handleName}
                />
              </td>
            </tr>
            <tr>
              <td>연락처</td>
              <td>
                <input
                  type='number'
                  placeholder='하이픈(-) 없이 입력'
                  className={ phoneError ? 'step6_inputError' : 'step6_inputPass' }
                  defaultValue={phone}
                  onChange={handlePhone}
                  onWheel={ event => event.currentTarget.blur() }
                />
              </td>
            </tr>
            <tr>
              <td>이메일 주소</td>
              <td>
                <div className='email_id'>
                  <input
                      type='text'
                      placeholder='아이디'
                      className={ emailIDError ? 'step6_inputError' : 'step6_inputPass' }
                      defaultValue={emailId}
                      onChange={handleEmailId}
                  />
                  <div className='hyper'>@</div>
                </div>
                <input 
                    type='text'
                    className={ domainError ? 'step6_inputError' : 'step6_inputPass' }
                    value={domain || ''}
                    onChange={handleDomain}
                  />
                <Dropdown>
                  <select name='domain' onChange={handleDomain}>
                    <option value=''>직접 입력</option>
                    <option value='naver.com'>naver.com</option>
                    <option value='hanmail.net'>hanmail.net</option>
                    <option value='daum.net'>daum.net</option>
                    <option value='nate.com'>nate.com</option>
                    <option value='gmail.com'>gmail.com</option>
                    <option value='hotmail.com'>hotmail.com</option>
                  </select>
                  <Arrow>
                    <img src={arrow} alt='dropdown' />
                  </Arrow>
                </Dropdown>
              </td>
            </tr>
          </tbody>
        </TableBox>
        <div className='last_description'>*입력한 정보를 토대로 산출한 예상 매매가는 입력한 이메일주소로 발송됩니다.</div>
      </InputBox>
      <FlexButton>
        <NextFalse onClick={prevStep}>이전</NextFalse>
        { regExp_6()
          ? <NextTrue onClick={finalStep}>다음</NextTrue>
          : <NextFalse onClick={handleFalse}>다음</NextFalse>
        }      
      </FlexButton>
      { isChecked &&
        <ContinueModal modalButton={handleFalse} />
      }
      { finalCheck &&
        <LastCheckModal finalStep={finalStep} sendEmail={sendEmail} />
      }
    </>
  )
};

export default Chapter6;

const LastTitle = styled.div`
  @media screen and (min-width: 1025px) {
    font-size: 0.688rem;
    font-weight: 400;
    margin-left: 0.2rem;
    line-height: 2.7rem;
    color: #767676;  
  }

  @media screen and (max-width: 1024px) {
    font-size: 2.7vw;
    font-weight: 400;
    margin-left: 0.5vw;
    line-height: 15vw;
    color: #767676;
  }
`;

const InputBox = styled.section`
  @media screen and (min-width: 1025px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.688rem;
    padding: 1.3rem;
    border: 1px solid #d9d9d9;
    .last_description {
      margin: 0 auto 2.25rem 10rem;
      font-size: 0.8rem;
      color: #ff0000;
    }  
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 4.5vw;
    padding: 7vw 3vw;
    border: 1px solid #d9d9d9;
    .last_description {
      width: 76vw;
      font-size: 3vw;
      line-height: 4vw;
      margin: 0 auto;
      color: #ff0000;
    }  
  }
`;

/** tr: nth-child(3) { td: nth-child(2) { 이메일 data } }
  * InputBox의 하단 border때문에 height를 정하지 않고 padding-bottom처리
  * email Input의 width => 겹침으로 더이상 안늘어나서 padding-right로 늘림
  */
const TableBox = styled.table`
  @media screen and (min-width: 1025px) {
    border-collapse: separate;
    border-spacing: 10px;

    tr {
      height: 3rem;
      td {
        width: 4rem;
        font-size: 1rem;
        font-weight: 600;

        input {
          width: 16.5rem;
          &: focus {
            outline: none;
            border: 1px solid #007fff;
          }
          ::placeholder {
            color: #bebebe;
            font-size: 0.938rem;
          }
        }

        .step6_inputPass {
          height: 2rem;
          padding-left: 0.5rem;
          font-size: 0.875rem;
          border: 1px solid #d9d9d9;
        }
        .step6_inputError {
          height: 2rem;
          padding-left: 0.5rem;
          font-size: 0.875rem;
          border: 1px solid #ff0000;
        }
      }
      .hyper {
        margin: 0 0.3rem;
        font-size: 1.3rem;
        line-height: 2rem;
        color: #bfbfbf;
      }
    }

    .email_id {
      display: flex;
    }

    tr: nth-child(3) {
      height: 2rem;
      td: nth-child(2) {
        display: flex;
        input {
          padding: 0 1rem 0 0.5rem;
          width: 9rem;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    border-collapse: separate;
    border-spacing: 10px;
    padding-bottom: 21vw;
    tr {
      width: 80vw;
      height: 17vw;
      display: flex;
      flex-direction: column;
      margin-bottom: 8.5vw;

      td {
        margin-bottom: 2vw;
        font-size: 5.2vw;
        font-weight: 600;

        input {
          &: focus {
            outline: none;
            border: 1px solid #007fff;
          }
          ::placeholder {
            color: #bebebe;
            font-size: 4vw;
          }
        }

        .step6_inputPass {
          width: 75vw;
          height: 10.5vw;
          padding-left: 1.5vw;
          font-size: 3.2vw;
          border: 1px solid #d9d9d9;
        }
        .step6_inputError {
          width: 75vw;
          height: 10.5vw;
          padding-left: 1.5vw;
          font-size: 3.2vw;
          border: 1px solid #ff0000;
        }
      }
      .hyper {
        margin: 0 auto;
        width: 6vw;
        font-size: 4.5vw;
        line-height: 11vw;
        color: #bfbfbf;
      }
    }

    .email_id {
      display: flex;
    }

    tr: nth-child(3) {
      td: nth-child(2) {
        display: flex;
        flex-direction: column;

        input {
          margin-bottom: 2vw;
          &: nth-child(1) {
            width: 63vw;
          }
        }
      }
    }
  }
`;

const Dropdown = styled.div`
  @media screen and (min-width: 1025px) {
    position: relative;
    margin-left: 0.5rem;
    width: 11rem;
    height: 2.2rem;

    select {
      position: relative;
      width: inherit;
      height: inherit;
      background-color: transparent;
      padding: 0.1rem 0.5rem;
      font-size: 0.938rem;

      color: #bebebe;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      z-index: 3;

      &: focus {
        outline: none;
        border: 1px solid #007fff;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    position: relative;
    width: 77.5vw;
    height: 11.5vw;

    select {
      position: relative;
      width: inherit;
      height: inherit;
      padding: 0.3vw 1.5vw;
      font-size: 4vw;

      color: #bebebe;
      background-color: transparent;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      z-index: 3;

      &: focus {
        outline: none;
        border: 1px solid #007fff;
      }
    }
  }
`;

const Arrow = styled.span`
  @media screen and (min-width: 1025px) {
    position: absolute; 
    top: 0.05rem; 
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 2rem;
    height: inherit;
    border-left: 1px solid #d9d9d9;
    z-index: 1;

    img {
      width: 0.8rem;
      height: 0.5rem;
    }
  }

  @media screen and (max-width: 1024px) {
    position: absolute; 
    top: 0.05vw; 
    right: 0; 
    display: flex;
    justify-content: center;
    align-items: center;

    width: 13vw; 
    height: inherit;
    border-left: 1px solid #d9d9d9;
    z-index: 1;
  
    img {
      width: 3vw;
      height: 2vw;
    }  
  }
`;