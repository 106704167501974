import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import title_1 from '../../assets/Main/title-1.webp';
import truck_1 from '../../assets/Main/truck-1.webp';
import model_2 from '../../assets/Main/model-2.webp';
import subTitle_2 from '../../assets/Main/titleSub-2.webp';
import model_3 from '../../assets/Main/model-3.webp';
import model_4 from '../../assets/Main/model-4.webp';
import calculation from '../../assets/Main/calculation.webp';

function MobileMainPage() {
  const navigate = useNavigate();
  const goToOrder = () => {
    navigate('/order', { replace: true });
    document.documentElement.scrollTo(0, 0);
  };

  return (
    <>
      <MobileWrap>
        <MobileArticle1>
          <MobileTitle1 />
          <MobileTruck />
        </MobileArticle1>

        <MobileBackground>
          <MobileArticle2>
              <TextWrapper2>
                <Text2>
                  <div className='color_text2'>사고 팔 때</div>
                  <div>알기 어려운</div>
                  <div>매입 기준들</div>
                </Text2>
                <SubText2 />
              </TextWrapper2>
              <Model2 />
          </MobileArticle2>
        </MobileBackground>

        <MobileArticle1>
          <Text3>
            <div className='color_text3'>안전하고 빠르게</div>
            <div>확인할 수 있는</div>
            <div>방법 없을까?</div>
          </Text3>
          <MobileModel3 />
        </MobileArticle1>

        <MobileBackground>
          <MobileArticle2>
            <Text4>
              <div>찾았다!</div>
              <div className='color_text4'>퀵사구팔구로 해결</div>
            </Text4>
            <MobileSubText4>
              <div>당사에서 다년 간의 연구 끝에 개발한</div>
              <div>퀵서비스 및 화물운송주선업체의</div>
              <div>적정 밸류에이션 모델을 기반으로</div>
              <div>최적의 예상 매매가를 산출합니다.</div>
            </MobileSubText4>
            <MobileModel4 />
          </MobileArticle2>
        </MobileBackground>

        <MobileArticle5>
          <Text5>
            <div className='color_text5'>5분 만에</div>
            <div className='color_text5'>예상 매매가</div>
            <div>확인하기!</div>
          </Text5>
          <MobileModel5 />
        </MobileArticle5>
        <MobileButton>
          <div className="button1" onClick={goToOrder}>매매가 바로 확인하기</div>
        </MobileButton>
      </MobileWrap>
    </>
  )
};

export default MobileMainPage;

export const MobileWrap = styled.main`
  @media screen and (min-width: 1025px) {
    display: none;
  }
  max-width: 100vw;
  width: 100vw;
`;


/* 배너 1 (배너하나가 화면 꽉차게) */
export const MobileArticle1 = styled.article`
  width: 90vw;
  height: calc(100vh - 8vh);

  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;
export const MobileTitle1 = styled.div`
  width: 90vw;
  height: 40vh;

  background-image: url(${title_1});
  background-repeat: no-repeat;
  background-size: 38vh;
  background-position: center;
`;
export const MobileTruck = styled.div`
  width: 90vw;
  height: 40vh;

  background-image: url(${truck_1});
  background-repeat: no-repeat;
  background-size: 50vh;
  background-position: center;
`;


/* 배너 2 */
export const MobileBackground = styled.article`
  width: 100vw;
  background-color: #f4f8fd;
`;
export const MobileArticle2 = styled.section`
  width: 90vw;
  height: calc(100vh - 8vh);

  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;
export const TextWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5vh auto 0 auto;
`;
export const Text2 = styled.div`
  width: 50vw;
  height: 18vh;

  display: flex;
  flex-direction: column;
  margin: 2vh auto 0 auto;
  font-size: 4.2vh;
  font-weight: bold;
  line-height: 6vh;
  .color_text2 {
    color: #007fff;
  }
`;
export const SubText2 = styled.div`
  width: 42vw;
  height: 15vh;
  background-image: url(${subTitle_2});
  background-repeat: no-repeat;
  background-size: 15vh;
  background-position: left;
`;
export const Model2 = styled.div`
  width: 90vw;
  height: 52vh;
  background-image: url(${model_2});
  background-repeat: no-repeat;
  background-size: 34vh;
  background-position: bottom;
`;


/* 배너 3 */
export const Text3 = styled.div`
  width: 66vw;
  height: 20vh;

  display: flex;
  flex-direction: column;
  margin: 5vh auto 3vh auto;
  font-size: 4.2vh;
  font-weight: bold;
  line-height: 6.5vh;
  div {
    margin: 0 auto 0 0;
  }
  .color_text3 {
    color: #007fff;
  }
`;
export const MobileModel3 = styled.div`
  width: 90vw;
  height: 60vh;
  background-image: url(${model_3});
  background-repeat: no-repeat;
  background-size: 33vh;
  background-position: center;
`;


/* 배너 4 */
export const Text4 = styled.div`
  width: 71vw;
  height: 13vh;

  display: flex;
  flex-direction: column;
  margin: 7.5vh auto 3vh auto;
  font-size: 4.2vh;
  font-weight: bold;
  line-height: 6vh;
  div {
    margin: 0 auto;
  }
  .color_text4 {
    color: #007fff;
  }
`;
export const MobileSubText4 = styled.div`
  width: 90vw;
  height: 15vh;

  display: flex;
  flex-direction: column;
  font-size: 1.8vh;
  line-height: 3vh;
  color: #68707c;
  div {
    margin: 0 auto;
  }
`;
export const MobileModel4 = styled.div`
  width: 90vw;
  height: 49vh;

  margin-top: 10vh;
  background-image: url(${model_4});
  background-repeat: no-repeat;
  background-size: 45vh;
  background-position: bottom;
`;


/* 배너 5 */
export const MobileArticle5 = styled.article`
  width: 90vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;
export const MobileModel5 = styled.div`
  width: 90vw;
  height: 40vh;
  margin-top: 5vh;

  background-image: url(${calculation});
  background-repeat: no-repeat;
  background-size: 30vh;
  background-position: center;
`;
export const Text5 = styled.div`
  width: 46vw;
  height: 20vh;

  display: flex;
  flex-direction: column;
  margin: 6vh auto 0 auto;
  font-size: 4.2vh;
  font-weight: bold;
  line-height: 6.5vh;
  div {
    margin: 0 auto 0 0;
  }
  .color_text5 {
    color: #007fff;
  }
`;

export const MobileButton = styled.div`
  width: 100vw;
  height: 8vh;

  position: fixed;
  bottom: 0;
  font-size: 2.5vh;
  line-height: 8vh;
  text-align: center;
  background-color: #007fff;
  .button1 {
    color: #fff;
  }
`;
