import React from 'react';
import { hydrate, render } from 'react-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import App from './App';
import GlobalStyle from './GlobalStyle';

import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { rootReducer } from './store/reducers';

const store = createStore(rootReducer);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <Provider store={store}>
        <GlobalStyle />
        <App />
      </Provider>
    </React.StrictMode>, rootElement);
} else {
  render(
    <React.StrictMode>
      <Provider store={store}>
        <GlobalStyle />
        <App />
      </Provider>
    </React.StrictMode>, rootElement);
}