import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import lastCheck from '../../assets/Modal/lastCheck.webp';
import { chargeFilter } from '../../lib/function';

interface footerProps {
  finalStep: any;
  sendEmail: any;
}

const LastCheckModal = ({finalStep, sendEmail}: footerProps) => {
  /* useSelector */
  const storeData = useSelector((store: RootState) => store.dataReducer);
  
  return (
    <>
    <ModalWrapper>
      <FinalModalPop>
        <Notices>
          <Logo />
          <Text>
            <div>한 번 더 확인해 보세요!</div>
            <ReCheck>
              <StoreData>
                <div className='dataTitle'>사업자 종류 :</div>
                <div className='dataValue'>{storeData.registration_type}</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>사업자 등록번호 :</div>
                <div className='dataValue'>{storeData.registration_number}</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>상호명 :</div>
                <div className='dataValue'>{storeData.corporation_name}</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>소재지 :</div>
                <div className='dataValue'>{storeData.address}</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>상세주소 :</div>
                <div className='dataValue'>{storeData.address_detail}</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>신용거래 :</div>
                <div className='dataValue'>{chargeFilter(storeData.credit_sales)} 만 원</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>카드결제 거래 :</div>
                <div className='dataValue'>{chargeFilter(storeData.card_sales)} 만 원</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>현금거래 :</div>
                <div className='dataValue'>{chargeFilter(storeData.cash_sales)} 만 원</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>순 매출액 :</div>
                <div className='dataValue'>{chargeFilter(storeData.monthly_net_sales)} 만 원</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>오토바이 :</div>
                <div className='dataValue'>{chargeFilter(storeData.motorcycle_call)} 건</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>다마스/라보 :</div>
                <div className='dataValue'>{chargeFilter(storeData.damas_call)} 건</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>트럭 :</div>
                <div className='dataValue'>{chargeFilter(storeData.truck_call)} 건</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>자기사 비율 :</div>
                <div className='dataValue'>{storeData.corporation_driver_ratio} %</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>이름 :</div>
                <div className='dataValue'>{storeData.customer_name}</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>연락처 :</div>
                <div className='dataValue'>{storeData.phone}</div>
              </StoreData>
              <StoreData>
                <div className='dataTitle'>이메일 :</div>
                <div className='dataValue'>{storeData.email}</div>
              </StoreData>
            </ReCheck>
          </Text>
        </Notices>
        <Buttons>
          <StopBtn onClick={finalStep}>
            수정하기
          </StopBtn>
          <ContinueBtn onClick={sendEmail}>예상매매가 확인하기</ContinueBtn>
        </Buttons>
      </FinalModalPop>
    </ModalWrapper>
    </>
  );
};

export default LastCheckModal;

/* overflow때문에 modalCore안쓰고 따로 선언 */
export const ModalWrapper = styled.div`
  @media screen and (min-width: 1025px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
  }
  @media screen and (max-width: 1024px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    overflow-y: scroll;
  }
`;

/* 경고 팝업 */
export const FinalModalPop = styled.article`
  @media screen and (min-width: 1025px) {
    display: flex;
    flex-direction: column;

    width: 39.25rem;
    height: 48rem;
    background-color: #fff;

    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 100;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;

    width: 94vw;
    height: 160vw;
    background-color: #fff;

    z-index: 100;
  }
`;

const Notices = styled.section`
  @media screen and (min-width: 1025px) {
    padding: 1.5rem 3rem 0 3rem;
    height: 44rem;
  }

  @media screen and (max-width: 1024px) {
    padding: 3vw 4vw 2vw 4vw;
    height: 143vw;
  }
`;

const Logo = styled.div`
  @media screen and (min-width: 1025px) {
    width: 4.6rem;
    height: 4.6rem;
    background-image: url(${lastCheck});
    background-repeat: no-repeat;
    background-size: 3.8rem;
    background-position: left;
    image-rendering: -webkit-optimize-contrast;
  }

  @media screen and (max-width: 1024px) {
    width: 14vw;
    height: 14vw;
    background-image: url(${lastCheck});
    background-repeat: no-repeat;
    background-size: 12vw;
    background-position: left;
  }
`;

const Text = styled.div`
  @media screen and (min-width: 1025px) {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.8rem;
    margin: 1.1rem 0 2.2rem 0;
  }

  @media screen and (max-width: 1024px) {
    font-size: 3.8vw;
    font-weight: 700;
    line-height: 6.5vw;
    margin-top: 3vw;
  }
`;

const ReCheck = styled.div`
  @media screen and (min-width: 1025px) {
    display: flex;
    flex-direction: column;
    margin-top: 1.1rem;
    padding: 0.5rem;
    font-weight: 500;
    border: 1px solid #d9d9d9;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin-top: 3vw;
    padding: 3vw 2.5vw;
    font-weight: 500;
    border: 1px solid #d9d9d9;
  }
`;

const StoreData = styled.div`
  @media screen and (min-width: 1025px) {
    display: flex;
    width: 100%;
    .dataTitle {
      margin-right: 0.5rem;
      width: 8rem;
      font-size: 0.93rem;
      font-weight: 600;
    }
    .dataValue {
      font-size: 0.93rem;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    width: 100%;
    .dataTitle {
      width: 23vw;
      font-size: 2.5vw;
      font-weight: 600;
    }
    .dataValue {
      margin-left: 3vw;
      font-size: 2.5vw;
      font-weight: 400;
    }
  }
`;

const Buttons = styled.section`
  @media screen and (min-width: 1025px) {
    display: flex;
    align-items: center;
    height: 3.5rem;

    font-size: 1.1rem;
    font-weight: 550;
    text-align: center;
    line-height: 3.5rem;

    color: #fff;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    height: 12vw;

    font-size: 3vw;
    font-weight: 550;
    text-align: center;
    line-height: 12.5vw;

    color: #fff;
  }
`;

const StopBtn = styled.div`
  @media screen and (min-width: 1025px) {
    width: 15rem;
    background-color: #747474;
    &:hover {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 36vw;
    background-color: #747474;
  }
`;

// width -> 확대*축소할때 간헐적으로 빈공간 생긴다면 calc 이용
const ContinueBtn = styled.div`
  @media screen and (min-width: 1025px) {
    width: calc(100% - 15rem);
    background-color: #007fff;
    &:hover {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 1024px) {
    width: calc(100% - 36vw);
    background-color: #007fff;
  }
`;
