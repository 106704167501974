import styled from "styled-components";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

/* 이용약관 & 개인정보 */
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  width: 90%;
  height: 80%;
  background-color: #fff;

  overflow-y: scroll;
  z-index: 100;
`;

/* 경고 팝업 */
export const ModalPopup = styled.div`
@media screen and (min-width: 1025px) {
  display: flex;
  flex-direction: column;

  width: 31rem;
  height: 18rem;
  background-color: #fff;

  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);
  z-index: 100;
}

@media screen and (max-width: 1024px) {
  display: flex;
  flex-direction: column;

  width: 85vw;
  height: 50vw;
  background-color: #fff;

  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);
  z-index: 100;
}

`;

/* 주소검색 */
export const ModalAddress = styled.div`
@media screen and (min-width: 1025px) {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  width: 30rem;
  height: 40em;
  background-color: #fff;

  border-radius: 50px;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);
  z-index: 100;
}

@media screen and (max-width: 1024px) {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3vh 3vw;

  width: 100vw;
  height: 80vh;
  background-color: #fff;

  z-index: 100;
}
`;