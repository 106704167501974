import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import MobileFooter from '../Mobile/MobileFooter';
import closeBtn from '../../assets/Modal/x-icon.png';

interface MobileModalProps {
  modalButton: any;
  gotoHome: any;
  gotoOrder: any;
  step: number | null;
}

const MobileSide = ({modalButton, gotoHome, gotoOrder, step}: MobileModalProps) => {
  const location = useLocation();

  return (
    <>
      <MobileWrap>
        <Overlay onClick={modalButton}></Overlay>
        <MobileSubWrap>
          <BtnWrap>
            <CloseButton onClick={modalButton} />
          </BtnWrap>
          <MobileContent>
          { location.pathname === '/' || step === 7 ? (
            <>
              <header>
                <a
                  href='/'
                  className={location.pathname === '/' ? "thismobile" : "notmobile"}>
                  홈
                </a>
              </header>
              <header>
                <div
                  onClick={gotoOrder}
                  className={location.pathname === '/order' ? "thismobile" : "notmobile"}>
                  매매가확인
                </div>
              </header>
            </>
          ) : (
            <>
              <header>
                <div
                  onClick={gotoHome}
                  className={location.pathname === '/' ? "thismobile" : "notmobile"}>
                  홈
                </div>
              </header>
              <header>
                <div
                  onClick={gotoOrder}
                  className={location.pathname === '/order' ? "thismobile" : "notmobile"}>
                  매매가확인
                </div>
              </header>
            </>
          ) }
          </MobileContent>

          <MobileFooter />
        </MobileSubWrap>
      </MobileWrap>
    </>
  );
};

export default MobileSide;

export const MobileWrap = styled.article`
  @media screen and (min-width: 1025px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    position: fixed;
    top: 0;
    right: 0;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
`;

export const Overlay = styled.div`
  @media screen and (min-width: 1025px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);  
  }
`;

const MobileSubWrap = styled.section`
  @media screen and (min-width: 1025px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    width: 75vw;

    height: 100vh;
    background-color: #fff;
    z-index: 100;
    overflow-y: scroll;
  }
`;
const BtnWrap = styled.div`
  @media screen and (min-width: 1025px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 13vw;
  }
`;
const CloseButton = styled.div`
  @media screen and (min-width: 1025px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    margin-right: 7vw;
    width: 6vw;
    height: 6vw;
    background-image: url(${closeBtn});
    background-repeat: no-repeat;
    background-size: 3.5vw;
    background-position: center;
  }
`;

/* main높이는 footer 높이 차감 */
const MobileContent = styled.div`
  @media screen and (min-width: 1025px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    min-height: calc(100vh - 32vh);

    display: flex;
    flex-direction: column;
    margin: 5vw 0 0 5vw;
    header {
      height: 12vw;
      .thismobile {
        width: 25vw;
        height: 5vw;
        font-size: 3.5vw;
        font-weight: 500;
        color: #000000;
      }
      .notmobile {
        width: 25vw;
        height: 5vw;
        font-size: 3.5vw;
        font-weight: 500;
        color: #000000;
    }
  }
`;