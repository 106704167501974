import React from 'react';
import styled from 'styled-components';
import { ModalWrapper, ModalPopup } from '../css/ModalCore';
import continuing from '../../assets/Modal/continuing.webp';

interface footerProps {
  modalButton: any;
}

const ContinueModal = ({modalButton}: footerProps) => {
  return (
    <>
    <ModalWrapper>
      <ModalPopup>
        <Notices>
          <Logo />
          <Text>
            <div>정보를 정확하게 입력해주세요.</div>
            <section>빠진 부분 없이 입력해야 다음단계로 넘어갑니다.</section>
          </Text>
        </Notices>
        <Buttons>
          <ContinueBtn onClick={modalButton}>작성계속하기</ContinueBtn>
        </Buttons>
      </ModalPopup>
    </ModalWrapper>
    </>
  );
};

export default ContinueModal;

const Notices = styled.div`
  @media screen and (min-width: 1025px) {
    padding: 1.5rem 3rem 0 3rem;
    height: 15rem;
  }

  @media screen and (max-width: 1024px) {
    padding: 5vw 6vw 0 6vw;
    height: 45vw;
  }
`;

const Logo = styled.div`
  @media screen and (min-width: 1025px) {
    margin-top: 1rem;
    width: 5rem;
    height: 5rem;
    background-image: url(${continuing});
    background-repeat: no-repeat;
    background-size: 4rem;
    image-rendering: -webkit-optimize-contrast;
  }

  @media screen and (max-width: 1024px) {
    width: 13vw;
    height: 13vw;
    background-image: url(${continuing});
    background-repeat: no-repeat;
    background-size: 11vw;
  }
`;

const Text = styled.div`
  @media screen and (min-width: 1025px) {
    margin-top: 0.3rem;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.8rem;
    section {
      font-size: 1rem;
      font-weight: normal;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-top: 2vw;
    font-size: 4vw;
    font-weight: 700;
    line-height: 6vw;
    section {
      font-size: 3vw;
      font-weight: normal;
    }
  }
`;

const Buttons = styled.div`
  @media screen and (min-width: 1025px) {
    display: flex;
    align-items: center;
    height: 3.5rem;
    color: #fff;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    height: 11vw;
    color: #fff;
    cursor: pointer;
  }
`;

const ContinueBtn = styled.div`
  @media screen and (min-width: 1025px) {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 3.5rem;
    background-color: #007fff;
    &:hover {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 3vw;
    font-weight: 550;
    line-height: 10vw;
    background-color: #007fff;
  }
`;
