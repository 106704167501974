import { step1State, step2State, step3State, step4State, step5State, step6State } from "../../common/Type";

export const step1Data = (data: step1State) => {
  return {
    type: 'STEP_ONE_DATA',
    payload: data
  };
};
export const step2Data = (data: step2State) => {
  return {
    type: 'STEP_TWO_DATA',
    payload: data
  };
};
export const step3Data = (data: step3State) => {
  return {
    type: 'STEP_THREE_DATA',
    payload: data
  };
};
export const step4Data = (data: step4State) => {
  return {
    type: 'STEP_FOUR_DATA',
    payload: data
  };
};
export const step5Data = (data: step5State) => {
  return {
    type: 'STEP_FIVE_DATA',
    payload: data
  };
};
export const step6Data = (data: step6State) => {
  return {
    type: 'STEP_SIX_DATA',
    payload: data
  };
};