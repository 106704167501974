import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { step3Data } from '../../store/actions';
import { RootState } from '../../store/reducers';
import { step3State, stepProps } from '../../common/Type';

import ContinueModal from '../../common/Modal/ContinueModal';
import { Step, StepNum, StepTitle, FlexButton, NextTrue, NextFalse, CommonInputBox, Information } from '../../common/css/OrderCore';

function Chapter3({step, setStep, isChecked, setIsChecked, prevStep}: stepProps) {
  /* useSelector */
  const storeData = useSelector((store: RootState) => store.dataReducer);
  const dispatch = useDispatch();
  
  const [ netSale, setNetSale ] = useState(storeData.monthly_net_sales);

  const handleSale = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNetSale(e.target.value); // 월 평균 순매출액
  };

  const nextStep = () => {
    setStep(step + 1);
    document.documentElement.scrollTo(0, 0);

    const inputData: step3State = {
      monthly_net_sales: netSale
    };
    dispatch(step3Data(inputData));
  };

  /* 금액 정규표현식 */
  const moneyCheck = /^[0-9]+$/;
  const regSales = moneyCheck.test(netSale);

  /* 필수 입력 체크 */
  const [ salesError, setSalesError ] = useState(false);

  const handleFalse = () => {
    setIsChecked(!isChecked);

    if (!regSales) {
      setSalesError(true);
    } else {
      setSalesError(false);
    }
  };

  return (
    <>
      <Step>
        <StepNum>Step 3</StepNum>
        <StepTitle>월 평균 매출액 정보</StepTitle>
      </Step>
      <CommonInputBox>
        <p>월 평균 순 매출액</p>
        <div className='input_description'>
          <div>월 평균 순매출액은 매출액에서 기사에게 지급한 비용을 제외한 금액을 말합니다. 즉, 수수료의 합계액을 의미합니다.</div>
          <div>오토바이 퀵서비스 오더 하나를 2만 원에 배차해서 수수료를 20%를 기사로부터 받는다면 이 때 순매출액은 4,000원이 됩니다.</div>
        </div>
        <Information>
          <input
            type='number'
            className={ salesError ? 'inputError' : 'inputPass'}
            defaultValue={netSale}
            onChange={handleSale}
            onWheel={ event => event.currentTarget.blur() }
          />
          <div>만 원</div>
        </Information>
      </CommonInputBox>
      <FlexButton>
        <NextFalse onClick={prevStep}>이전</NextFalse>
        { regSales
          ? <NextTrue onClick={nextStep}>다음</NextTrue>
          : <NextFalse onClick={handleFalse}>다음</NextFalse>
        }
      </FlexButton>
      { isChecked && 
        <ContinueModal modalButton={handleFalse} />
      }
    </>
  )
};

export default Chapter3;