/**
  * @description 얕은 복사 함수
  * 프로퍼티 변경으로도 원본 객체 유지
  * 중첩객체가 아니어서 얕은 복사를 사용
  * @return { let obj2 = copyObject(obj1) }
  */
  export const copyObject = (target: any) => {
    let result: any = {};
    for (let prop in target) {
      result[prop] = target[prop];
    };
    return result;
  };

  /* 천단위 마다 콤마를 추가하는 필터 */
  export const chargeFilter = (charge: string) => {
    return charge
    ? charge.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : charge;
  };