import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}

	* body{
    box-sizing: border-box;
    font-family: 'Spoqa Han Sans Neo', sans-serif !important;
  }
  
  body {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* 링크에 색상 및 밑줄 없애기 */
  a {
    color: inherit;
    text-decoration: none;
  }

  /* input number 화살표 없애기 */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media screen and (max-width: 1024px) {
    min-width: 100vw;
    min-height: 100vh;
    max-width: 100vw;
  }

  /* Select 기본 화살표 없애기 */
  select::-ms-expand {
    display: none;
  }
  select {
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export default GlobalStyle;