import React, { useState } from 'react';
import styled from 'styled-components';
// import FooterModal  from '../../common/Modal/FooterModal';
// import { privacyText, termText } from '../../common/Text';

const Footer = () => {
  const [ term, setTerm ] = useState(false);
  const [ privacy, setPrivacy ] = useState(false);

  const openTermModal = () => {
    setTerm(!term);
  };

  const openPrivacyModal = () => {
    setPrivacy(!privacy);
  };

  return (
    <>
    <FooterBlock>
      <Wrapper>
        <Banner>
          <div onClick={openTermModal}>서비스 이용약관</div>
          <span> | </span>
          <div onClick={openPrivacyModal}>개인정보 처리방침</div>
        </Banner>
        <Description>
          <MainText>
            <div>(주)알고랩</div>
            <div className='footer_reserved'>2021 ⓒ AlgoLab Co., Ltd. all rights reserved.</div>
          </MainText>
          <SubText>
            <div>사이트 일체의 정보, 콘텐츠, UI, 이미지 등을</div>
            <div className='footer_description'>어떠한 경우로도 전시, 전송, 스크래핑 등의 무단 사용을 금지합니다.</div>
          </SubText>
        </Description>
      </Wrapper>
    </FooterBlock>
    {/* { term &&
      <FooterModal
        modalButton={openTermModal}
        text={termText}
      />
    }
    { privacy &&
      <FooterModal 
        modalButton={openPrivacyModal}
        text={privacyText}
      />
    } */}
    </>
  );
};

export default Footer;

/* ---Footer---
 * fixed와 bottom: 0을 쓰지않고
 * main 높이를  min-height: calc(100vh - footer) 설정
 */

const FooterBlock = styled.footer`
  @media screen and (max-width: 1024px) {
    display: none;
  }
  width: 100%;
  height: 23rem;
  background-color: #d5dde8;
`;

const Wrapper = styled.article`
  width: 70rem;

  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-left: 20.5rem;
`;

const Banner = styled.section`
  display: flex;
  margin-top: 3.375rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: #5e718b;
  span {
    padding: 0 0.5rem;
    font-size: 1rem;
  }
  // div {
  //   cursor: pointer;
  // }
`;

const Description = styled.section`
`;

const MainText = styled.div`
  margin-top: 8.5rem;
  font-size: 0.75rem;
  color: #5e718b;
  .footer_reserved {
    margin-top: 0.5rem;
  }
`;

const SubText = styled.div`
  margin: 1.063rem 0 4.438rem 0;
  font-size: 0.75rem;
  color: #8b98a8;
  .footer_description {
    margin-top: 0.5rem;
  }
`;