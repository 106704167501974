import { initalStateType } from "../../common/Type";

const dataReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case 'STEP_ONE_DATA':
      return {
        ...state,
        registration_type: action.payload.registration_type,
        registration_number: action.payload.registration_number,
        corporation_name: action.payload.corporation_name,
        address: action.payload.address,
        address_detail: action.payload.address_detail
      };
    case 'STEP_TWO_DATA':
      return {
        ...state,
        credit_sales: action.payload.credit_sales,
        card_sales: action.payload.card_sales,
        cash_sales: action.payload.cash_sales,
        monthly_total_sales: action.payload.monthly_total_sales
      };
      case 'STEP_THREE_DATA':
        return {
          ...state,
          monthly_net_sales: action.payload.monthly_net_sales
        };
      case 'STEP_FOUR_DATA':
        return {
          ...state,
          motorcycle_call: action.payload.motorcycle_call,
          damas_call: action.payload.damas_call,
          truck_call: action.payload.truck_call,
          daily_total_call: action.payload.daily_total_call      
        }
      case 'STEP_FIVE_DATA':
        return {
          ...state,
          corporation_driver_ratio: action.payload.corporation_driver_ratio
        }
      case 'STEP_SIX_DATA':
        return {
          ...state,
          customer_name: action.payload.customer_name,
          phone: action.payload.phone,
          email: action.payload.email  
        }

    default:
      return state;
  };
};

export default dataReducer;

const INITIAL_STATE: initalStateType = {
  registration_type: '',
  registration_number: '',
  corporation_name: '',
  address: '',
  address_detail: '',
  credit_sales: '',
  card_sales: '',
  cash_sales: '',
  monthly_total_sales: '',
  monthly_net_sales: '',
  motorcycle_call: '',
  damas_call: '',
  truck_call: '',
  daily_total_call: '',
  corporation_driver_ratio: '',
  customer_name: '',
  phone: '',
  email: ''
};