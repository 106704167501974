import React, { useState } from 'react';
import styled from 'styled-components';
import StopModal  from '../../common/Modal/StopModal';

import Header from '../Header/Header';
import MobileHeader from '../Mobile/MobileHeader';
import Chapter1 from './Chapter1';
import Chapter2 from './Chapter2';
import Chapter3 from './Chapter3';
import Chapter4 from './Chapter4';
import Chapter5 from './Chapter5';
import Chapter6 from './Chapter6';
import Chapter7 from './Chapter7';
import Footer from '../Footer/Footer';
import calculation from '../../assets/Main/calculation.webp';

function OrderPage() {
  const [ step, setStep ] = useState(1);

  /* 페이지 이탈 방지 */
  const [ blocked, setBlocked ] = useState(false);
  const blockPage = () => {
    setBlocked(!blocked);
  };

  /* 정확히 입력해주세요 */
  const [ isChecked, setIsChecked ] = useState(false);

  /**
   * @description react router v6
   * @returns { document.documentElement.scrollTo(0, 0) } document.getElementById('root')(X)
   */
  const prevStep = () => {
    setStep(step - 1);
    document.documentElement.scrollTo(0, 0);
  };

  return (
    <>
    <Header step={step} blocked={blocked} setBlocked={setBlocked} />
    <MobileHeader step={step} blocked={blocked} setBlocked={setBlocked} />

    <OrderWrap>
      { step === 7 ? <></> : 
      <Banner>
        <Article>
          <Section>
            <MainText>
              퀵서비스/화물운송주선업체 <span>예상 매매가 확인</span>
            </MainText>
            <SubText4>
              <div>당사에서 다년 간의 연구 끝에 개발한 퀵서비스 및 화물운송주선업체의</div>
              <div>적정 밸류에이션 모델을 기반으로 최적의 예상 매매가를 산출합니다.</div>
            </SubText4>
          </Section>
          <Model5 />
        </Article>
      </Banner>
      }
      <Contents>
        {step === 1 ?
          <Chapter1
            step={step}
            setStep={setStep}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            prevStep={prevStep}
          />
        :
        step === 2 ?
          <Chapter2
            step={step}
            setStep={setStep}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            prevStep={prevStep}
          />
        :
        step === 3 ?
          <Chapter3
            step={step}
            setStep={setStep}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            prevStep={prevStep}
          />
        :
        step === 4 ?
          <Chapter4
            step={step}
            setStep={setStep}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            prevStep={prevStep}
          />
        :
        step === 5 ?
          <Chapter5
            step={step}
            setStep={setStep}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            prevStep={prevStep}
          />
        :
        step === 6 ?
          <Chapter6
            step={step}
            setStep={setStep}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            prevStep={prevStep}
          />
        :
        <Chapter7 />
        }
      </Contents>
    </OrderWrap>

    <Footer />
    { blocked && <StopModal modalButton={blockPage} />}
    </>
  )
};

export default OrderPage;

/* main => min-height: calc(100vh - footer) */
export const OrderWrap = styled.main`
  @media screen and (min-width: 1025px) {
    width: 100%;
    min-height: calc(100vh - 23rem);

    padding-top: 5rem;
  }

  @media screen and (max-width: 1024px) {
    width: 100vw;
  }
`;

export const Banner = styled.header`
  @media screen and (min-width: 1025px) {
    width: 100%;
    background-color: #f7f7f7;
  }

  @media screen and (max-width: 1024px) {
    width: 100vw;
    height: 32vw;
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
  }
`;

export const Article = styled.article`
  @media screen and (min-width: 1025px) {
    width: 70rem;

    display: flex;
    justify-content: center;
    margin: 0 auto 2vw auto;
    padding: 3rem 0;
  }

  @media screen and (max-width: 1024px) {
    width: 90vw;

    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
`;

const Section = styled.section`
  @media screen and (min-width: 1025px) {
    display: flex;
    flex-direction: column;
    margin: auto 1rem auto 0;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;

const MainText = styled.div`
  @media screen and (min-width: 1025px) {
    margin: 0 auto 0.8rem auto;
    width: 33rem;
    height: 2rem;
    font-size: 1.688rem;
    font-weight: bold;
    span {
      color: #007fff;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 80vw;
    height: 6vw;
    font-size: 4.1vw;
    line-height: 6.1vw;
    font-weight: bold;
    margin: 2vw auto;
    text-align: center;
    span {
      color: #007fff;
    }
  }
`;

const SubText4 = styled.div`
  @media screen and (min-width: 1025px) {
    width: 28.8rem;
    font-size: 1rem;
    line-height: 1.7rem;
    color: #68707c;
  }

  @media screen and (max-width: 1024px) {
    margin: 0 auto;
    font-size: 2.5vw;
    line-height: 4vw;
    color: #68707c;
  }
`;

const Model5 = styled.div`
  @media screen and (min-width: 1025px) {
    width: 15rem;
    height: 13rem;
    background-image: url(${calculation});
    background-repeat: no-repeat;
    background-size: 13rem;
    background-position: center;
    image-rendering: -webkit-optimize-contrast;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const Contents = styled.article`
  @media screen and (min-width: 1025px) {
    width: 50rem;

    display: flex;
    flex-direction: column;
    margin: 3.875rem auto 1rem auto;
  }

  @media screen and (max-width: 1024px) {
    width: 90vw;
    display: flex;
    flex-direction: column;
    margin: 10vw auto 0 auto;
  }
`;
