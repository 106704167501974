import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assets/Header/logo.webp';

/** 
 * @description react-router v6
 * @return { useNavigate() } useHistory()(X)
 * @return { navigate('/') } history.push('/')(X)
 * @return { navigate('/', {replace: true}) } -> history.replace('/')(X)
 * 
 * @description history.location.pathname -> useLocation()
 * @return { location.pathname }
 */

interface headerProps {
  step: number;
  blocked: boolean;
  setBlocked: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header = ({step, blocked, setBlocked}: headerProps) => {
  /* useSelector */
  const storeData = useSelector((store: RootState) => store.dataReducer);

  const navigate = useNavigate();
  const location = useLocation();

  /** 
   * @return { StopModal } => (데이터입력한)Step1 + Step2 ~ 6
   * @return { navigate('/') } => (데이터 미입력)Step1 + 마지막페이지
   */
  const gotoHome = () => {
    if (
        (
          step === 1 &&
          storeData.registration_type !== '' &&
          storeData.registration_number !== '' &&
          storeData.corporation_name !== '' &&
          storeData.address !== '' &&
          storeData.address_detail !== ''
        ) || (
          step === 2 ||
          step === 3 ||
          step === 4 ||
          step === 5 ||
          step === 6
        )
      ) {
        return setBlocked(!blocked);
      }
      else {
        return navigate('/', { replace: true });
      }
  };

  const gotoOrder = () => {
    navigate('/order', { replace: true });
  };

  return (
    <>
    { location.pathname === '/' || step === 7 ? (
      <HeaderBlock>
        <Wrapper>
          <a href='/'>
            <Logo />
          </a>
          <SubWrapper>
            <a
              href='/'
              className={location.pathname === '/' ? "thispage" : "notpage"}>
              홈
            </a>
            <span> | </span>
            <button
              onClick={gotoOrder}
              className={location.pathname === '/order' ? "thispage" : "notpage"}>
              매매가확인
            </button>
          </SubWrapper>
        </Wrapper>
      </HeaderBlock>
    ) : (
      <HeaderBlock>
        <Wrapper>
          <button onClick={gotoHome}>
            <Logo />
          </button>
          <SubWrapper>
            <button
              onClick={gotoHome}
              className={location.pathname === '/' ? "thispage" : "notpage"}>
              홈
            </button>
            <span> | </span>
            <button
              onClick={gotoOrder}
              className={location.pathname === '/order' ? "thispage" : "notpage"}>
              매매가확인
            </button>
          </SubWrapper>
        </Wrapper>
      </HeaderBlock>
    )}
    </>
  );
};

export default Header;

/* ---Header---
 * top: 0을 주지않는 fixed
 * main에서 padding-top으로 처리
 */

const HeaderBlock = styled.nav`
  @media screen and (max-width: 1024px) {
    display: none;
  }
  position: fixed;
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  z-index: 99;
`;

const Wrapper = styled.article`
  width: 70rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  height: 4.9rem;
  button {
    background-color: inherit;
    border: none;
    cursor: pointer;
  }
  a {
    padding: 1px 6px;
  }
`;

const Logo = styled.div`
  width: 13rem;
  height: 2.1rem;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: 11rem;
  background-position: center;
  image-rendering: -webkit-optimize-contrast;
`;

const SubWrapper = styled.section`
  width: 12rem;
  text-align: center;

  span {
    padding: 0 0.5rem;
    font-size: 0.875rem;
    color: #6f6f6f;
  }
  .thispage {
    display: inline-block;
    height: 100%;
    font-size: 0.875rem;
    font-weight: bold;
    color: #6f6f6f;
    cursor: pointer;
    background-color: inherit;
    border: none;
  }
  .notpage {
    display: inline-block;
    height: 100%;
    font-size: 0.875rem;
    font-weight: normal;
    color: #6f6f6f;
    cursor: pointer;
    background-color: inherit;
    border: none;
  }
`;