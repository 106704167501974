import React from 'react';
import styled from 'styled-components';
import DaumPostcode from 'react-daum-postcode';
import { ModalWrapper, Overlay, ModalAddress } from '../css/ModalCore';
import btn from '../../assets/Modal/x-icon.png';

interface addressProps {
  openAddressModal: any;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
}

const KaKaoPostCodeModal = ({openAddressModal, setAddress}: addressProps) => {
  /* react-daum-postcode Package */
  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = ''; 
    
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    };

    setAddress(fullAddress);
    openAddressModal();
  };
  /* postcode css */
  const modalStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    zIndex: '100'
  };

  return (
    <>
    <ModalWrapper>
      <Overlay onClick={openAddressModal}></Overlay>
      <ModalAddress>
        <BtnWrapper>
          <button onClick={openAddressModal}>
            <Logo />
          </button>
        </BtnWrapper>
        <DaumPostcode
          onComplete={handleComplete}
          style={modalStyle}
        />
      </ModalAddress>
    </ModalWrapper>
    </>
  );
};

export default KaKaoPostCodeModal;

const BtnWrapper = styled.div`
@media screen and (min-width: 1025px) {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  button {
    margin-bottom: 1rem;
    background: inherit;
    border: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  button {
    margin-bottom: 1vh;
    background: inherit;
    border: none;
  }
}
`;

const Logo = styled.div`
@media screen and (min-width: 1025px) {
  width: 2rem;
  height: 2rem;
  background-image: url(${btn});
  background-repeat: no-repeat;
  background-size: 1.5rem;
}

@media screen and (max-width: 1024px) {
  width: 8vw;
  height: 4vh;
  background-image: url(${btn});
  background-repeat: no-repeat;
  background-size: 3vh;
}
`;