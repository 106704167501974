import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { useNavigate, useLocation } from 'react-router-dom';
import MobileSide from './MobileSide';
import logo from '../../assets/Header/logo.webp';
import MenuButton from '../../assets/Header/menu-line.png';

interface headerProps {
  step: number | null;
  blocked: boolean | null;
  setBlocked: any;
}

const MobileHeader = ({step, blocked, setBlocked}: headerProps) => {
  /* useSelector */
  const storeData = useSelector((store: RootState) => store.dataReducer);

  const navigate = useNavigate();
  const location = useLocation();

  /** 
   * @return { StopModal } => (데이터입력한)Step1 + Step2 ~ 6
   * @return { navigate('/') } => (데이터 미입력)Step1 + 마지막페이지
   */
  const gotoHome = () => {
    if (
        (
          step === 1 &&
          storeData.registration_type !== '' &&
          storeData.registration_number !== '' &&
          storeData.corporation_name !== '' &&
          storeData.address !== '' &&
          storeData.address_detail !== ''
        ) || (
          step === 2 ||
          step === 3 ||
          step === 4 ||
          step === 5 ||
          step === 6
        )
      ) {
        return setBlocked(!blocked);
      }
      else {
        return navigate('/', { replace: true });
      }
  };
  const gotoOrder = () => {
    navigate('/order', { replace: true });
  };

  /* 모바일 slide */
  const [ side, setSide ] = useState(false);
  const handleSide = () => {
    setSide(!side);
  };

  return (
    <>
      <HeaderBlock>
        <Wrapper>
          { location.pathname === '/' || step === 7 ? (
            <a href='/'>
              <Logo />
            </a>
          ) : (
            <button onClick={gotoHome}>
              <Logo />
            </button>
          )}
          <Menu onClick={handleSide} />
        </Wrapper>
      </HeaderBlock>
      { side && 
        <MobileSide 
          modalButton={handleSide}
          gotoHome={gotoHome}
          gotoOrder={gotoOrder}
          step={step}
        />
      }
    </>
  );
};

export default MobileHeader;

/* Mobile Header는 fixed없이 height: 8vh */

const HeaderBlock = styled.nav`
  @media screen and (min-width: 1025px) {
    display: none;
  }
  max-width: 100vw;
  width: 100vw;

  background: #fff;
  border-bottom: 1px solid rgb(216 216 216);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  z-index: 99;
`;

const Wrapper = styled.article`
  width: 90vw;
  height: 13vw;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  a {
    margin-left: 1.5vw;
  }
  button {
    background-color: inherit;
    border: none;
  }
`;

const Logo = styled.div`
  width: 35vw;
  height: 8vw;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: 30vw;
  background-position: left;
`;

const Menu = styled.div`
  width: 7vw;
  height: 7vw;
  background-image: url(${MenuButton});
  background-repeat: no-repeat;
  background-size: 4vw;
  background-position: left;
`;
