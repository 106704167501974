import styled from "styled-components";
import title_1 from '../../assets/Main/title-1.webp';
import truck_1 from '../../assets/Main/truck-1.webp';
import model_2 from '../../assets/Main/model-2.webp';
import subTitle_2 from '../../assets/Main/titleSub-2.webp';
import model_3 from '../../assets/Main/model-3.webp';
import model_4 from '../../assets/Main/model-4.webp';
import calculation from '../../assets/Main/calculation.webp';

/* main => min-height: calc(100vh - footer) */
export const Wrapper = styled.main`
  @media screen and (max-width: 1024px) {
    display: none;
  }
  width: 100%;
  padding-top: 5rem;
  min-height: calc(100vh - 23rem);
`;

/* 배너 1 */
export const Article1 = styled.article`
  width: 70rem;

  display: flex;
  margin: 0 auto;
  padding: 7.5rem 0 4rem 3rem;
`;
export const Section1 = styled.section`
  display: flex;
  flex-direction: column;
`;
export const ImgTitle1 = styled.div`
  width: 25.5rem;
  height: 14rem;
  background-image: url(${title_1});
  background-repeat: no-repeat;
  background-size: 25rem;
  image-rendering: -webkit-optimize-contrast;
`;
export const OrderButton = styled.div`
  margin-top: 2.5rem;
  width: 16rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  background-color: #007fff;
  border-radius: 5px;
  box-shadow: 0px 10px 10px -5px rgba(1 129 251 / 55%);
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  .button1 {
    color: #fff;
  }
`;
export const ImgTruck = styled.div`
  width: 42rem;
  height: 26rem;
  background-image: url(${truck_1});
  background-repeat: no-repeat;
  background-size: 40rem;
  background-position: bottom;
  image-rendering: -webkit-optimize-contrast;
`;


/* 배너 2 */
export const SubWrapper2 = styled.article`
  width: 100%;
  background-color: #f4f8fd;
`;
export const Article2 = styled.section`
  width: 70rem;

  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top: 5rem;
`;
export const Model2 = styled.div`
  width: 22rem;
  height: 24rem;
  background-image: url(${model_2});
  background-repeat: no-repeat;
  background-size: 19rem;
  image-rendering: -webkit-optimize-contrast;
`;
export const TextWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Text2 = styled.div`
  width: 15rem;
  height: 10rem;

  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 1.5rem;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 3rem;
  .color_text2 {
    color: #007fff;
  }
`;
export const SubText2 = styled.div`
  width: 9rem;
  height: 6rem;
  margin-left: 0.5rem;
  background-image: url(${subTitle_2});
  background-repeat: no-repeat;
  background-size: 7rem;
  background-position: center;
  image-rendering: -webkit-optimize-contrast;
`;


/* 배너 3 */
export const Article3 = styled.article`
  width: 70rem;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 4rem;
`;
export const Text3 = styled.div`
  width: 18rem;
  height: 10rem;

  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 3.5rem;
  .color_text3 {
    color: #007fff;
  }
`;
export const Model3 = styled.div`
  width: 16rem;
  height: 25rem;
  margin-left: 4rem;
  background-image: url(${model_3});
  background-repeat: no-repeat;
  background-size: 15rem;
  image-rendering: -webkit-optimize-contrast;
`;


/* 배너 4 */
export const Article4 = styled.section`
  width: 70rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding-top: 5rem;
`;
export const Section4 = styled.div`
  margin: 0 auto;
`;
export const Text4 = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  width: 22rem;
  height: 7rem;
  font-size: 2.5rem;

  font-weight: bold;
  line-height: 3.5rem;
  .color_text4 {
    color: #007fff;
  }
  div {
    margin: 0 auto;
  }
`;
export const SubText4 = styled.div`
  margin-top: 0.7rem;
  font-size: 1rem;
  line-height: 1.7rem;
  color: #68707c;
  div {
    text-align: center;
  }
`;
export const Model4 = styled.div`
  margin: 1rem auto 0 auto;
  width: 23rem;
  height: 22rem;
  background-image: url(${model_4});
  background-repeat: no-repeat;
  background-size: 21.8rem;
  background-position: center;
  image-rendering: -webkit-optimize-contrast;
`;


/* 배너 5 */
export const Article5 = styled.article`
  width: 70rem;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem auto;
  padding: 4rem;
`;
export const Model5 = styled.div`
  width: 19rem;
  height: 18rem;

  margin-right: 2rem;
  background-image: url(${calculation});
  background-repeat: no-repeat;
  background-size: 16rem;
  background-position: center;
  image-rendering: -webkit-optimize-contrast;
`;
export const Section5 = styled.section`
  display: flex;
  flex-direction: column;
`;
export const Text5 = styled.div`
  width: 16rem;
  height: 10.5rem;

  display: flex;
  flex-direction: column;
  font-size: 2.5rem;

  font-weight: bold;
  line-height: 3.5rem;
  .color_text5 {
    color: #007fff;
  }
`;
