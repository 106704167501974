import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from '../Header/Header';
import MobileHeader from '../Mobile/MobileHeader';
import MobileMainPage from '../Mobile/MobileMainPage';
import Footer from '../Footer/Footer';
import { Wrapper } from '../../common/css/MainCore';
import { Article1, Section1, ImgTitle1, OrderButton, ImgTruck } from '../../common/css/MainCore';
import { SubWrapper2, Article2, Model2, TextWrapper2, Text2, SubText2 } from '../../common/css/MainCore';
import { Article3, Text3, Model3 } from '../../common/css/MainCore';
import { Article4, Section4, Text4, SubText4, Model4 } from '../../common/css/MainCore';
import { Article5, Model5, Section5, Text5 } from '../../common/css/MainCore';


function MainPage() {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({ duration: 1200 })
  });

  const goToOrder = () => {
    navigate('/order', { replace: true });
    document.documentElement.scrollTo(0, 0);
  };

  /* 조건부 Header를 위한 headerProps용도 (의미없음) */
  const [ step ] = useState(0);
  const [ blocked, setBlocked ] = useState(false);

  return (
    <>
      <Header step={step} blocked={blocked} setBlocked={setBlocked}/>
      <MobileHeader step={step} blocked={blocked} setBlocked={setBlocked} />

      <MobileMainPage />

      <Wrapper>
        <Article1>
            <Section1 data-aos="fade-right">
              <ImgTitle1 />
              <OrderButton>
                <div className="button1" onClick={goToOrder}>매매가 바로 확인하기</div>
              </OrderButton>
            </Section1>
            <ImgTruck data-aos="fade-left" />
        </Article1>

        <SubWrapper2>
          <Article2>
              <Model2 data-aos="fade-right" />
              <TextWrapper2 data-aos="fade-left">
                <Text2>
                    <div className='color_text2'>사고 팔 때</div>
                    <div>알기 어려운</div>
                    <div>매입 기준들</div>
                </Text2>
                <SubText2 />
              </TextWrapper2>
          </Article2>
        </SubWrapper2>

        <Article3>
          <Text3 data-aos="fade-right">
            <div className='color_text3'>안전하고 빠르게</div>
            <div>확인할 수 있는</div>
            <div>방법 없을까?</div>
          </Text3>
          <Model3 data-aos="fade-left" />
        </Article3>

        <SubWrapper2>
          <Article4>
            <Section4 data-aos="zoom-in">
              <Text4>
                <div>찾았다!</div>
                <div className='color_text4'>퀵사구팔구로 해결</div>
              </Text4>
              <SubText4>
                <div>당사에서 다년 간의 연구 끝에 개발한 퀵서비스 및 화물운송주선업체의</div>
                <div>적정 밸류에이션 모델을 기반으로 최적의 예상 매매가를 산출합니다.</div>
              </SubText4>
              <Model4 />
            </Section4>
          </Article4>
        </SubWrapper2>

        <Article5>
          <Model5 data-aos="fade-right" />
          <Section5 data-aos="fade-left">
            <Text5>
              <div className='color_text5'>5분 만에</div>
              <div className='color_text5'>예상 매매가</div>
              <div>확인하기!</div>
            </Text5>
            <OrderButton>
              <div className="button1" onClick={goToOrder}>매매가 바로 확인하기</div>
            </OrderButton>
          </Section5>
        </Article5>
      </Wrapper>

      <Footer />
    </>
  )
};

export default MainPage;