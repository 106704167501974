import React from 'react';
import styled from 'styled-components';
import complete from '../../assets/Modal/complete.webp';

export const Chapter7 = () => {
  return (
    <EndBox>
      <EndImg />
      <EndText>
        <div className='end_title'>감사합니다!</div>
        <div className='end_subTitle'>
          입력한 정보를 토대로 산출한 예상 매매가
          <div className='end_middleTitle'>
            를 입력한
            <span className='end_color'> 이메일주소로 발송해드렸습니다.</span>
          </div>
        </div>
      </EndText>
      <EndButton href='/'>홈으로</EndButton>
    </EndBox>
  )
};

export default Chapter7;

const EndBox = styled.section`
  @media screen and (min-width: 1025px) {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
  }

  @media screen and (max-width: 1024px) {
    width: 80vw;
    display: flex;
    flex-direction: column;
    margin: 15vw auto 0 auto;
  }
`; 

const EndImg = styled.div`
  @media screen and (min-width: 1025px) {
    width: 10rem;
    height: 10rem;
    background-image: url(${complete});
    background-repeat: no-repeat;
    background-size: 7rem;
    image-rendering: -webkit-optimize-contrast;
  }

  @media screen and (max-width: 1024px) {
    width: 35vw;
    height: 37vw;
    margin: 0 auto;
    background-image: url(${complete});
    background-repeat: no-repeat;
    background-size: 24vw;
    background-position: center;
  }
`;

const EndText = styled.div`
  @media screen and (min-width: 1025px) {
    margin-top: 2rem;
    .end_title {
      font-size: 1.75rem;
      font-weight: 700;
    }
    .end_subTitle {
      margin-top: 1rem;
      font-size: 1.2rem;
      font-weight: 300;
    }
    .end_middleTitle {
      display: inline;
    }
    .end_color {
      color: #007fff;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-top: 5vw;
    .end_title {
      width: 60vw;
      margin: 0 auto;
      text-align: center;
      font-size: 7vw;
      font-weight: 700;
    }
    .end_subTitle {
      width: 80vw;
      margin: 4vw auto;
      text-align: center;
      font-size: 4.2vw;
      font-weight: 300;
      line-height: 6vw;
    }
    .end_color {
      display: inline;
      width: 75vw;
      margin: 1vw auto;
      text-align: center;
      font-weight: 400;
      color: #007fff;
    }
  }
`;

const EndButton = styled.a`
  @media screen and (min-width: 1025px) {
    margin: 3.188rem 0 14.813rem 0;
    width: 6.688rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 1.063rem;
    font-weight: 500;
    text-align: center;

    color: #fff;
    background-color: #007fff;
    box-shadow: 0px 10px 10px -5px rgba(1 129 251 / 55%);
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 28vw;
    height: 12vw;
    font-size: 4.2vw;
    margin: 6vw auto;
    font-weight: 500;
    line-height: 12.5vw;
    text-align: center;

    color: #fff;
    background-color: #007fff;
    box-shadow: 0px 10px 10px -5px rgba(1 129 251 / 55%);
    border-radius: 5px;
  }
`;
