import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { step2Data } from '../../store/actions';
import { RootState } from '../../store/reducers';
import { step2State, stepProps } from '../../common/Type';

import ContinueModal from '../../common/Modal/ContinueModal';
import { Step, StepNum, StepTitle, FlexButton, NextTrue, NextFalse, AverageBox, Thick, Light, AverageSales, Underline, CommonInputBox, Information } from '../../common/css/OrderCore';
import { chargeFilter } from '../../lib/function';

function Chapter2({step, setStep, isChecked, setIsChecked, prevStep }: stepProps) {
  /* useSelector */
  const storeData = useSelector((store: RootState) => store.dataReducer);
  const dispatch = useDispatch();

  const [ credit, setCredit ] = useState(storeData.credit_sales);
  const [ card, setCard ] = useState(storeData.card_sales);
  const [ cash, setCash] = useState(storeData.cash_sales);
  const [ monthlyTotal, setMonthlyTotal ] = useState(storeData.monthly_total_sales);

  const handleCredit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredit(e.target.value); // 신용거래
  };
  const handleCard = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCard(e.target.value); // 카드결제 거래
  };
  const handleCash = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCash(e.target.value); // 현금거래
  };
  useEffect(() => {
    const handelTotal = () => {
      if ( credit !== '' && card !== '' && cash !== '')
      return setMonthlyTotal(
        (parseInt(credit) + parseInt(card) + parseInt(cash)).toString()
      );
    }
    handelTotal();
  },[card, cash, credit]);

  /* store에 데이터 저장 */
  const nextStep = () => {
    setStep(step + 1);
    document.documentElement.scrollTo(0, 0);

    const inputData: step2State = {
      credit_sales: credit,
      card_sales: card,
      cash_sales: cash,
      monthly_total_sales: monthlyTotal
    };
    dispatch(step2Data(inputData));
  };

  /* 금액 정규표현식 */
  const moneyCheck = /^[0-9]+$/;
  const regCredit = moneyCheck.test(credit);
  const regCard = moneyCheck.test(card);
  const regCash = moneyCheck.test(cash);

  /* 필수 입력 체크 */
  const [ creditError, setCreditError ] = useState(false);
  const [ cardError, setCartError ] = useState(false);
  const [ cashError, setCashError ] = useState(false);

  const handleFalse = () => {
    setIsChecked(!isChecked);

    // 신용거래
    if (!regCredit) {
      setCreditError(true);
    } else {
      setCreditError(false);
    };
    // 카드거래
    if (!regCard) {
      setCartError(true);
    } else {
      setCartError(false);
    };
    // 현금거래
    if (!regCash) {
      setCashError(true);
    } else {
      setCashError(false);
    };
  };

  /* [ 다음 ] 버튼 활성화 */
  const regExp_2 = () => {
    if(
      regCredit &&
      regCard &&
      regCash &&
      monthlyTotal !== ''
    ) return true;
    return false;
  };

  return (
    <>
      <Step>
        <StepNum>Step 2</StepNum>
        <StepTitle>월 평균 매출액 정보</StepTitle>
      </Step>
      <CommonInputBox>
        <p>신용거래</p>
        <div className='input_description'>
          <div>월간 거래액 중에서 후불로 정산하는 신용거래처의 매출액을 공급가액 기준으로 입력합니다.</div>
          <div>세금계산서 등 법적증빙이 이루어지지 않는 후불거래는 현금거래로 취급합니다.</div>
        </div>
        <Information>
          <input
            type='number'
            className={ creditError ? 'inputError' : 'inputPass'}
            defaultValue={credit}
            onChange={handleCredit}
            onWheel={ event => event.currentTarget.blur() }
          />
          <div>만 원</div>
        </Information>
      </CommonInputBox>
      <CommonInputBox>
        <p>개별 세금계산서발행 및 카드결제 거래</p>
        <div className='input_description'>
          <div>신용거래처는 아니나 고객이 퀵서비스 사무실로 퀵비를 계좌이체한 후 세금계산서 등</div>
          <div>법적증빙이 정상적으로 이루어진 매출액과 신용카드로 결제한 매출액을 공급가액 기준으로 입력합니다.</div>
        </div>
        <Information>
          <input
            type='number'
            className={ cardError ? 'inputError' : 'inputPass'}
            defaultValue={card}
            onChange={handleCard}
            onWheel={ event => event.currentTarget.blur() }
          />
          <div>만 원</div>
        </Information>
      </CommonInputBox>
      <CommonInputBox>
        <p>현금거래</p>
        <div className='input_description'>
          <div>고객이 퀵비를 현장에서 배송기사에게 현금으로 결제하거나 배송기사의 계좌로 이체 하는 등과 같이</div>
          <div>거래와 관련하여 퀵서비스 사무실에서 세금계산서 등 법적증빙을 하지 않은 매출액을 입력합니다.</div>
        </div>
        <Information>
          <input
            type='number'
            className={ cashError ? 'inputError' : 'inputPass'}
            defaultValue={cash}
            onChange={handleCash}
            onWheel={ event => event.currentTarget.blur() }
          />
          <div>만 원</div>
        </Information>
      </CommonInputBox>
      <AverageBox>
        <Thick />
        <Light />
        <AverageSales>
          <div className='chapter2_title'>
            월 평균 매출액
            <Underline />
          </div>
        </AverageSales>
        <div className='average_money'>{chargeFilter(monthlyTotal)}만 원</div>
      </AverageBox>
      <FlexButton>
        <NextFalse onClick={prevStep}>이전</NextFalse>
        { regExp_2()
          ? <NextTrue onClick={nextStep}>다음</NextTrue>
          : <NextFalse onClick={handleFalse}>다음</NextFalse>
        }
      </FlexButton>
      { isChecked && 
        <ContinueModal modalButton={handleFalse} />
      }
    </>
  )
};

export default Chapter2;