import React, { useState } from 'react';
import styled from 'styled-components';
// import FooterModal  from '../../common/Modal/FooterModal';
// import { privacyText, termText } from '../../common/Text';

const MobileFooter = () => {
  const [ mobileTerm, setMobileTerm ] = useState(false);
  const [ mobilePrivacy, setMobilePrivacy ] = useState(false);

  const mobileTermModal = () => {
    setMobileTerm(!mobileTerm);
  };

  const mobilePrivacyModal = () => {
    setMobilePrivacy(!mobilePrivacy);
  };

  return (
    <>
    <FooterBlock>
      <Wrapper>
        <Banner>
          <div onClick={mobileTermModal}>서비스 이용약관</div>
          <span> | </span>
          <div onClick={mobilePrivacyModal}>개인정보 처리방침</div>
        </Banner>
        <Description>
          <MainText>
            <div>(주)알고랩</div>
            <div className='footer_reserved'>2021 ⓒ AlgoLab Co., Ltd. all rights reserved.</div>
          </MainText>
          <SubText>
            <div>사이트 일체의 정보, 콘텐츠, UI, 이미지 등을</div>
            <div className='footer_description'>어떠한 경우로도 전시, 전송, 스크래핑 등의 무단 사용을 금지합니다.</div>
            {/* <div className='footer_description'>무단 사용을 금지합니다.</div> */}
          </SubText>
        </Description>
      </Wrapper>
    </FooterBlock>
    {/* { mobileTerm &&
      <FooterModal
        modalButton={mobileTermModal}
        text={termText}
      />
    }
    { mobilePrivacy &&
      <FooterModal 
        modalButton={mobilePrivacyModal}
        text={privacyText}
      />
    } */}
    </>
  );
};

export default MobileFooter;

/* ---Footer---
 * fixed와 bottom: 0을 쓰지않고
 * main 높이를  min-height: calc(100vh - footer) 설정
 */

const FooterBlock = styled.footer`
  @media screen and (min-width: 1025px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    width: 75vw;
    height: 18vh;
    margin-left: 5vw;
  }
`;

const Wrapper = styled.article`
  @media screen and (min-width: 1025px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;

const Banner = styled.section`
  @media screen and (min-width: 1025px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    font-size: 2.7vw;
    font-weight: 700;
    color: rgb(94, 113, 139);
    span {
      padding: 0 2vw;
      font-size: 2.5vw;
      font-weight: 700;
    }
  }
`;

const Description = styled.section`
@media screen and (min-width: 1025px) {
  display: none;
}

@media screen and (max-width: 1024px) {
  margin-top: 7vw;
}
`;

const MainText = styled.div`
  @media screen and (min-width: 1025px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    color: rgb(94, 113, 139);
    font-size: 2vw;
    .footer_reserved {
      margin-top: 3vw;
    }
  }
`;

const SubText = styled.div`
  @media screen and (min-width: 1025px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 4vw;
    font-size: 2vw;
    color: #8b98a8;
    .footer_description {
      margin-top: 1vw;
    }
  }
`;