import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { step1Data } from '../../store/actions';
import { RootState } from '../../store/reducers';
import { step1State, stepProps } from '../../common/Type';
import KaKaoPostCodeModal from '../../common/Modal/KaKaoPostCodeModal';

import ContinueModal from '../../common/Modal/ContinueModal';
import { Step, StepNum, StepTitle, FlexButton, NextTrue, NextFalse } from '../../common/css/OrderCore';
import search from '../../assets/Main/search.png';

function Chapter1({step, setStep, isChecked, setIsChecked}: stepProps) {
  /* useSelector */
  const storeData = useSelector((store: RootState) => store.dataReducer);
  const dispatch = useDispatch();

  /* e.target.value같은 실시간 데이터는 setState로 제어한 후 dispatch 해야한다 */
  const [ registType, setRegistType ] = useState(storeData.registration_type);
  const [ registNumber, setRegistNumber ] = useState(storeData.registration_number);
  const [ corporation, setCorporation ] = useState(storeData.corporation_name);
  const [ _address, _setAddress ] = useState(storeData.address);
  const [ addressDetail, setAddressDetail ] = useState(storeData.address_detail);
  const [ isOpen, setIsOpen ] = useState(false);

  const handleType = (e: React.MouseEvent<HTMLInputElement>) => {
    setRegistType((e.target as HTMLInputElement).value); // 사업자 종류
  };
  const handleNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegistNumber(e.target.value); // 사업자 등록번호
  };
  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCorporation(e.target.value); // 상호명
  };
  const openAddressModal = () => {
    setIsOpen(!isOpen); // 주소검색
  };
  const handleDetail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddressDetail(e.target.value); // 상세주소입력
  };

  /* store에 데이터 저장 */
  const nextStep = () => {
    setStep(step + 1);
    document.documentElement.scrollTo(0, 0);

    // reducer에 선언된 초기값과 동일한 타입으로 data 값 설정
    const inputData: step1State = {
      registration_type: registType,
      registration_number: registNumber,
      corporation_name: corporation,
      address: _address,
      address_detail: addressDetail
    };
    dispatch(step1Data(inputData));
  };

  /* 사업자등록번호 정규표현식 */
  const registCheck = /([0-9]{3})-?([0-9]{2})-?([0-9]{5})/;
  const regRegistNumber = registCheck.test(registNumber);

  /* 필수 입력 체크 */
  const [ typeError, setTypeError ] = useState(false);
  const [ numberError, setNumberError ] = useState(false);
  const [ corpError, setCorpError ] = useState(false);
  const [ addressError, setAddressError ] = useState(false);
  const [ detailError, setDetailError ] = useState(false);

  const handleFalse = () => {
    setIsChecked(!isChecked);

    // 사업자 종류
    if (registType === '') {
      setTypeError(true);
    } else {
      setTypeError(false);
    };
    // 사업자등록번호
    if (!regRegistNumber) {
      setNumberError(true);
    } else { 
      setNumberError(false);
    };
    // 상호명
    if ( corporation.trim() === '' ) {
      setCorpError(true);
    } else {
      setCorpError(false);
    };
    // 주소검색
    if ( _address === '' ) {
      setAddressError(true);
    } else {
      setAddressError(false);
    };
    // 상세주소입력
    if ( addressDetail.trim() === '' ) {
      setDetailError(true);
    } else {
      setDetailError(false);
    };
  };

  /* [ 다음 ] 버튼 활성화 */
  const regExp_1 = () => {
    if(
      registType !== '' &&
      regRegistNumber &&
      corporation.trim() !== '' &&
      _address !== '' &&
      addressDetail.trim() !==''
    ) return true;
    return false;
  };

  return (
    <>
      <Step>
        <StepNum>Step 1</StepNum>
        <StepTitle>사업자 정보</StepTitle>
      </Step>
      <InputBox>
        <TableBox>
          <tbody>
            <tr>
              <td>사업자 종류</td>
              <td>
                <label className={typeError ? 'radioError' : 'radioPass'}>
                  {registType === '법인' ?
                  (
                    <input
                      type='radio'
                      name='registration_type'
                      value='법인'
                      className='step1_radio'
                      onClick={handleType}
                      defaultChecked
                    />
                  ) : (
                    <input
                      type='radio'
                      name='registration_type'
                      value='법인'
                      className='step1_radio'
                      onClick={handleType}
                    />
                  )}
                    법인사업자
                </label>
                <label className={typeError ? 'radioError' : 'radioPass'}>
                  {registType === '개인' ?
                  (
                    <input
                      type='radio'
                      name='registration_type'
                      value='개인'
                      className='step1_radio'
                      onClick={handleType}
                      defaultChecked
                    />
                  ) : (
                    <input
                      type='radio'
                      name='registration_type'
                      value='개인'
                      className='step1_radio'
                      onClick={handleType}
                    />
                  )}
                    개인사업자
                </label>
              </td>
            </tr>
            <tr>
              <td>사업자등록번호</td>
              <td>
                <input
                  type='tel'
                  maxLength={10}
                  className={ numberError ? 'step1_inputError' : 'step1_inputPass' }
                  placeholder='하이픈(-) 없이 숫자 10자리만 입력'
                  defaultValue={registNumber}
                  onChange={handleNumber}
                />
              </td>
            </tr>
            <tr>
              <td>상호명</td>
              <td>
                <input
                  type='text'
                  className={ corpError ? 'step1_inputError' : 'step1_inputPass' }
                  defaultValue={corporation}
                  onChange={handleName}
                />
              </td>
            </tr>
            <tr>
              <td>소재지</td>
              <td className='position_search'>
                <input
                  type='text'
                  className={ addressError ? 'postcodeError' : 'postcodePass'}
                  placeholder='주소검색'
                  defaultValue={_address}
                  onClick={openAddressModal}
                  readOnly
                />
                <SearchIcon onClick={openAddressModal} />
              </td>
              <td>
                <input
                  type='text'
                  className={ detailError ? 'step1_inputError' : 'step1_inputPass' }
                  placeholder='상세주소입력'
                  defaultValue={addressDetail}
                  onChange={handleDetail}
                />
              </td>
            </tr>
          </tbody>
        </TableBox>
      </InputBox>
      <FlexButton>
        { regExp_1() 
          ? <NextTrue onClick={nextStep}>다음</NextTrue>
          : <NextFalse onClick={handleFalse}>다음</NextFalse>
        }
      </FlexButton>
      { isOpen && 
        <KaKaoPostCodeModal 
          openAddressModal={openAddressModal}
          setAddress={_setAddress}
        />
      }
      { isChecked && 
        <ContinueModal modalButton={handleFalse} />
      }
    </>
  )
};

export default Chapter1;

/* InputBox의 하단 border때문에 height를 정하지 않고 padding-bottom처리 */
const InputBox = styled.section`
@media screen and (min-width: 1025px) {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.688rem;
  padding: 2rem;
  border: 1px solid #d9d9d9;
}

@media screen and (max-width: 1024px) {
  padding-bottom: 5vw;
  display: flex;
  flex-direction: column;
  margin-bottom: 4.5vw;
  padding: 8.5vw 5vw 17vw 5vw;
  border: 1px solid #d9d9d9;
}
`;

const TableBox = styled.table`
@media screen and (min-width: 1025px) {
  border-collapse: separate;

  tr {
    height: 3rem;
    line-height: 3rem;
    .position_search {
      position: relative;
    }
    td {
      width: 11.2rem;
      font-size: 1rem;
      font-weight: 600;
      input {
        width: 16rem;
        height: 2rem;
        font-size: 0.875rem;
        padding-left: 0.5rem;
        border: 1px solid #d9d9d9;

        &: focus {
          outline: none;
          border: 1px solid #007fff;
        }
        ::placeholder {
          color: #bebebe;
          font-size: 0.938rem;
        }
      }

      .radioPass {
        margin-right: 1rem;
        height: 3rem;
        font-size: 0.875rem;
        font-weight: 350;
      }
      .radioError {
        color: #ff0000;
        margin-right: 1rem;
        height: 3rem;
        font-size: 0.875rem;
        font-weight: 350;
      }
      .step1_radio {
        margin: auto 0.5rem auto 0;
        width: 1rem;
        height: 1rem;
        vertical-align: middle;
      }
      .step1_inputPass {
        width: 16rem;
        height: 2rem;
        border: 1px solid #d9d9d9;
      }
      .step1_inputError {
        width: 16rem;
        height: 2rem;
        border: 1px solid #ff0000;
      }

      .postcodePass {
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
      .postcodeError {
        border: 1px solid #ff0000;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  border-collapse: separate;

  tr {
    width: 80vw;
    height: 23vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 2vw;

    .position_search {
      position: relative;
      margin-bottom: 1.2vw;
    }

    td {
      margin-bottom: 2.5vw;
      display: flex;
      font-size: 4.5vw;
      font-weight: 500;
      input {
        width: 90vw;
        height: 10.5vw;
        padding-left: 1.5vw;
        font-size: 3.5vw;
        border: 1px solid #d9d9d9;

        &: focus {
          outline: none;
          border: 1px solid #007fff;
        }
        ::placeholder {
          color: #bebebe;
          font-size: 4vw;
        }
      }

      .radioPass {
        margin-right: 1rem;
        font-size: 3vw;
        font-weight: 350;
      }
      .radioError {
        margin-right: 1rem;
        color: #ff0000;
        font-size: 3vw;
        font-weight: 350;
      }
      .step1_radio {
        margin: auto 0.5rem auto 0;
        width: 5vw;
        height: 8vw;
        vertical-align: middle;
      }  

      .step1_inputPass {
        border: 1px solid #d9d9d9;
      }
      .step1_inputError {
        border: 1px solid #ff0000;
      }

      .postcodePass {
      }
      .postcodeError {
        border: 1px solid #ff0000;
      }
    }
  }
}
`;

const SearchIcon = styled.div`
  @media screen and (min-width: 1025px) {
    position: absolute;
    right: 1rem;
    top: 0.85rem;
    width: 1.5rem;
    height: 1.5rem;
    background-image: url(${search});
    background-repeat: no-repeat;
    background-size: 1.4rem;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    position: absolute;
    right: 3vw;
    top: 3.2vw;
    width: 6vw;
    height: 6vw;
    background-image: url(${search});
    background-repeat: no-repeat;
    background-size: 5vw;
  }
`;